<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Yorumlar</v-toolbar-title>
            <v-spacer></v-spacer>
            RentiCar Ortalama Puanı: {{ rentiCarTotalAverage }}
          </v-toolbar>

          <CCardBody class="p-0">
            <!-- :sendToClipboard="sendToClipboard" -->

            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :defaultExcelExportParams="defaultExcelExportParams"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :enableBrowserTooltips="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
            >
            </ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>

    <CModal
      v-if="commentModalUpdate"
      title="Yorum Düzenle"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="commentModalUpdate"
      class="vendorLocationModalAddUpdateForm"
    >
      <CRow>
        <CCol col="3">
          <strong>Rezervasyon Kodu</strong>
        </CCol>
        <CCol col="9">
          <a
            target="_blank"
            :href="'/reservation/detail/' + modalUpdateForm.reservationCode"
            >{{ modalUpdateForm.reservationCode }}</a
          >
        </CCol>
      </CRow>

      <CRow>
        <CCol col="3">
          <strong>Anket Tarihi</strong>
        </CCol>
        <CCol col="9">
          {{
            moment(modalUpdateForm.createdAt).local().format("DD-MM-YYYY HH:mm")
          }}
        </CCol>
      </CRow>

      <hr />
      <CRow class="d-flex align-items-center">
        <CCol col="3">
          <strong>Durum</strong>
        </CCol>
        <CCol col="9">
          <CSelect
            class="mb-0"
            :value.sync="modalUpdateForm.status"
            :options="searchCommentStatusOptions"
          />
        </CCol>
      </CRow>
      <hr />

      <CRow>
        <CCol col="3">
          <strong>Lokasyon</strong>
        </CCol>
        <CCol col="9">
          {{ modalUpdateForm.location.locationName }}
        </CCol>
      </CRow>
      <hr />

      <CRow>
        <CCol col="3">
          <strong>Tedarikçi</strong>
        </CCol>
        <CCol col="9">
          {{ modalUpdateForm.vendor.brand }}
        </CCol>
      </CRow>
      <hr />

      <CRow>
        <CCol col="12">
          <strong>
            Genel Ortalama (
            {{ Number(modalUpdateForm.average).toFixed(1) }} )</strong
          >
        </CCol>
      </CRow>
      <CRow
        class="mt-2"
        v-for="(item, i) in modalUpdateForm.voteList"
        :key="i"
        :style="
          Number(item.vote) <= 3
            ? 'background-color:#E74C3C; color:#EBEBEB; padding:10px; font-weight:600; display:flex; align-items:center'
            : 'background-color:#5cb85c; color: #292b2c; padding:10px; font-weight:600; display:flex; align-items:center'
        "
      >
        <CCol col="8">
          {{ item.question }}
        </CCol>
        <CCol col="2">
          <CSelect
            :value.sync="item.vote"
            :options="[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
            ]"
          />
        </CCol>
        <CCol col="2">
          {{ item.description }}
        </CCol>
      </CRow>
      <hr />

      <CRow class="mb-3">
        <CCol col="3">
          <strong> Kullanıcı Adı</strong>
        </CCol>
        <CCol col="9">
          <CRow class="ml-1">
            <span
              >{{
                modalUpdateForm?.userID?.name +
                " " +
                modalUpdateForm?.userID?.lastname
              }}
            </span>
          </CRow>
        </CCol>
      </CRow>

      <CRow class="mb-3">
        <CCol col="3">
          <strong> Kullanıcı Telefon No:</strong>
        </CCol>
        <CCol col="9">
          <CRow class="ml-1">
            <span>{{ modalUpdateForm.userID.phone }} </span>
          </CRow>
        </CCol>
      </CRow>

      <CRow class="mb-3">
        <CCol col="3">
          <strong> Kullanıcı E-mail:</strong>
        </CCol>
        <CCol col="9">
          <CRow class="ml-1">
            <span>{{ modalUpdateForm.userID.email }} </span>
          </CRow>
        </CCol>
      </CRow>

      <!--- 
      <CRow class="d-flex align-items-center">
        <CCol col="3">
          <strong>Gizli</strong>
        </CCol>
        <CCol col="9">
          <CSelect
            class="mb-0"
            :value.sync="modalUpdateForm.private"
            :options="searchCommentPrivacyOptions"
          />
        </CCol>
      </CRow>
-->

      <CRow class="mb-3">
        <CCol col="3">
          <strong> Kullanıcı Yorumu </strong>
        </CCol>
        <CCol col="9">
          <CTextarea row="4" v-model="modalUpdateForm.content" />
          <CInputCheckbox
            id="flexCheckDefault"
            label="Yorumu gizle"
            @click="modalUpdateForm.private = !modalUpdateForm.private"
            :checked="modalUpdateForm.private"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol col="3">
          <strong> Kullanıcı Görseli </strong>
        </CCol>
        <CCol col="9">
          <CRow v-if="modalUpdateForm.image">
            <a
              :href="
                'https://cdn.renticar.com/comment/' + modalUpdateForm.image
              "
              target="_blank"
            >
              <CImg
                class="ml-3 rounded-lg"
                height="250"
                :src="
                  'https://cdn.renticar.com/comment/' + modalUpdateForm.image
                "
              />
            </a>
          </CRow>

          <CRow v-else> Görsel eklenmemiş </CRow>
        </CCol>
      </CRow>

      <CRow class="mb-3" v-if="modalUpdateForm.changes">
        <v-container>
          <div class="logs">
            <v-list-item
              v-for="(change, index) in reversedChanges"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ change.user }}</b> tarafından
                  {{ logGetter(change) }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  change.changeDateTime
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-container>
      </CRow>

      <template #footer>
        <RoleProvider slug="RESERVATION_COMMENT_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="canItPass"
              color="danger"
              @click="commentDelete(modalUpdateForm.reservationCode)"
            >
              Sil
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="RESERVATION_COMMENT_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="canItPass"
              color="success"
              :disabled="commentModalUpdateBtnDisable"
              @click="commentUpdateStatus()"
            >
              Güncelle
            </CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import Func from "../../func";
import { RoleProvider } from "../../provider";
const _ = require("lodash");

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    cellValue = moment(cellValue).toDate();
    cellValue = cellValue.getTime();
    filterLocalDateAtMidnight = filterLocalDateAtMidnight.getTime();
    if (cellValue == null) return 0;

    if (cellValue < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellValue > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2025,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};

export default {
  name: "Search",
  data() {
    return {
      logTypes: [
        {
          type: "private",
          name: "yorum gizliliği",
        },
        {
          type: "content",
          name: "kullanıcı içeriği",
        },
        {
          type: "status",
          name: "anket durumu",
        },
      ],
      logValues: [
        {
          value: "cancelled",
          name: "iptal edildi olarak güncellendi.",
        },
        {
          value: "pending",
          name: "onay bekliyor olarak güncellendi.",
        },
        {
          value: "active",
          name: "onaylandı.",
        },
        {
          value: true,
          name: "gizli olarak güncellendi.",
        },
        {
          value: false,
          name: "herkese açık olarak güncellendi.",
        },
      ],
      firstComments: {},
      changes: [],
      loggedUser: "",
      defaultExcelExportParams: {},
      searchCommentCollapsed: false,
      commentGridShow: false,
      commentModalUpdate: false,
      commentModalUpdateBtnDisable: false,

      // FORM
      modalUpdateForm: {},

      // SELECT OPTİONS
      searchCommentVendorOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchCommentLocationOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchCommentStatusOptions: [
        {
          value: "active",
          label: "Onaylandı",
        },
        {
          value: "pending",
          label: "Beklemede",
        },
        {
          value: "cancelled",
          label: "İptal Edildi",
        },
      ],

      // AGGRİD
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value == "active"
              ? "Aktif"
              : params.value == "pending"
              ? "Beklemede"
              : params.value == "cancelled"
              ? "İptal Edildi"
              : "";
          },
          cellStyle: (params) => {
            return params.value == "active"
              ? {
                  color: "#2eb85c",
                  fontWeight: "bold",
                }
              : params.value == "pending"
              ? {
                  color: "#f19d1d",
                  fontWeight: "bold",
                }
              : params.value == "cancelled"
              ? {
                  color: "#e55353",
                  fontWeight: "bold",
                }
              : "";
          },
          headerName: "Durum",
          width: 150,
        },
        {
          field: "reservationCode",
          headerName: "Rezervasyon Kodu",
          width: 150,
        },

        {
          field: "average",
          cellRenderer: (params) => {
            let average = Number(params.value).toFixed(1);

            return average;
          },
          headerName: "Puan",
          width: 125,
        },
        {
          field: "voteList",
          cellRenderer: (params) => {
            let rentipoint = 0;
            params.value
              .filter((item) => item.category == "renticar")
              .map((item) => {
                rentipoint = item.vote;
              });

            return rentipoint.toFixed(1);
          },
          headerName: "Renticar Puanı",
          width: 150,
        },
        {
          field: "reservations.driverInfo",

          cellRenderer: (params) => {
            if (params.value) {
              return params.value.name + " " + params.value.lastname;
            } else {
              return "Sürücü Bilgisi Bulunamadı";
            }
            // const { name, lastname } = params.value;
            // const fullName = `${name} ${lastname}` || "İsim Bulunamadı";
            // return fullName;
          },

          headerName: "Müşteri Adı",
          width: 250,
        },
        {
          field: "reservations.driverInfo.phone",
          headerName: "Müşteri Numarası",
          width: 250,
        },
        {
          field: "reservations.driverInfo.email",
          headerName: "Müşteri Maili",
          width: 250,
        },
        {
          field: "vendor.brand",
          headerName: "Firma",
          width: 200,
        },
        {
          field: "location.locationName",
          headerName: "Lokasyon",
          width: 400,
        },
        {
          field: "createdAt",

          headerName: "Anket Tarihi",
          cellRenderer: (params) => {
            if (params.value && params.value != "") {
              return moment(params.value).local().format("DD-MM-YYYY HH:mm");
            } else {
              return "";
            }
          },
          valueGetter: (params) => {
            if (params.data?.createdAt && params.data?.createdAt != null) {
              return (
                moment(params.data?.createdAt).local().format("YYYY-MM-DD") ||
                ""
              );
            } else {
              return "";
            }
          },
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          width: 150,
        },
        {
          field: "reservations.createdAt",
          headerName: "Rezervasyon Tarihi",
          cellRenderer: (params) => {
            if (params.value && params.value != "") {
              return moment(params.value).local().format("DD-MM-YYYY HH:mm");
            } else {
              return "";
            }
          },
          valueGetter: (params) => {
            if (
              params.data?.reservations?.createdAt &&
              params.data?.reservations?.createdAt != null
            ) {
              return moment(params.data.reservations?.createdAt || "")
                .local()
                .format("YYYY-MM-DD");
            } else {
              return "";
            }
          },
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          width: 150,
        },
        {
          field: "reservations.carDeliveryReturn.delivery.datetime",
          headerName: "Araç Teslim Alış",
          cellRenderer: (params) => {
            return moment(params.value).local().format("DD-MM-YYYY HH:mm");
          },
          valueGetter: (params) => {
            return moment(
              params.data.reservations?.carDeliveryReturn?.delivery?.datetime
            )
              .local()
              .format("YYYY-MM-DD");
          },
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          width: 150,
        },
        {
          field: "reservations.carDeliveryReturn.return.datetime",
          headerName: "Araç İade",
          cellRenderer: (params) => {
            return moment(params.value).local().format("DD-MM-YYYY HH:mm");
          },
          width: 150,
          valueGetter: (params) => {
            return moment(
              params.data.reservations?.carDeliveryReturn?.return?.datetime
            )
              .local()
              .format("YYYY-MM-DD");
          },
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },

        {
          field: "content",
          headerName: "Yorum",
          width: 400,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
    };
  },
  ///
  async created() {
    try {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/profile/")
        .then((response) => {
          if (response.data.result === "success") {
            this.loggedUser =
              response.data.profile.name + " " + response.data.profile.lastname;
          } else {
            switch (response.data.errCode) {
              case "PROFILE_INFO_NOT_FOUND":
                Vue.swal({
                  icon: "error",
                  title: "Profil",
                  html: "Profil bilgisi bulunamadı!",
                  confirmButtonText: "Tamam",
                });
                break;
            }
          }
        });
    } catch (e) {
      console.log(e.message);
    }

    try {
      const firstComments = await axios.get(
        process.env.VUE_APP_API_URL + "admin/comment/",
        {
          withCredentials: true,
        }
      );

      this.firstData = firstComments.data;
    } catch (e) {
      console.log(e.message);
    }
    const queryReservationCode = this.$route.query.reservationCode;

    queryReservationCode ? await this.searchComment() : this.searchComment();

    this.defaultExcelExportParams = {
      processCellCallback: (params) => {
        if (params.column.colDef.headerName === "Müşteri Adı") {
          const name = params.value?.name || "";
          const lastname = params.value?.lastname || "";
          const fullName = `${name} ${lastname}` || "İsim Bulunamadı";
          return fullName;
        } else if (params.column.colDef.headerName === "Anket Tarihi") {
          return moment(params.value).local().format("DD-MM-YYYY HH:mm");
        } else if (params.column.colDef.headerName === "Araç Teslim Alış") {
          return moment(params.value).local().format("DD-MM-YYYY HH:mm");
        } else if (params.column.colDef.headerName === "Araç İade") {
          return moment(params.value).local().format("DD-MM-YYYY HH:mm");
        } else if (params.column.colDef.headerName === "Rezervasyon Tarihi") {
          return moment(params.value).local().format("DD-MM-YYYY HH:mm");
        } else if (params.column.colDef.headerName === "Puan") {
          return Number(params.value.toFixed(1));
        } else if (params.column.colDef.headerName === "Renticar Puanı") {
          let rentipoint = 0;
          params.value
            .filter((item) => item.category == "renticar")
            .map((item) => {
              rentipoint = item.vote;
            });

          return Number(rentipoint.toFixed(1));
        } else {
          return params.value;
        }
      },
    };

    if (queryReservationCode) {
      const reservationFind = this.rowData.find(
        (item) => queryReservationCode == item.reservationCode
      );

      this.onRowDoubleClicked({ node: { data: reservationFind } });
    }
  },

  components: {
    AgGridVue,
    RoleProvider,
  },
  computed: {
    reversedChanges() {
      return this.modalUpdateForm.changes.reverse();
    },

    rentiCarTotalAverage() {
      let _avg = 0;
      let totalCount = 0;

      this.rowData
        .filter((data) => data.status !== "cancelled")
        .map((data) =>
          data.voteList.some((vote) => {
            if (vote.category == "renticar") totalCount += 1;
          })
        );

      this.rowData
        .filter((data) => data.status !== "cancelled")
        .map((item) => {
          item.voteList.map((vote) => {
            if (vote.category == "renticar") {
              _avg += vote.vote;
            }
          });
        });

      return (_avg / totalCount).toFixed(1) || 0;
    },
  },
  methods: {
    logGetter(change) {
      try {
        const types = this.logTypes;
        const values = this.logValues;

        const entries = types.filter(function (el) {
          return el.type == change.type;
        });
        const value = values.filter(function (el) {
          return el.value == change.value;
        });
        return (
          (entries[0]?.name || " ") +
          " " +
          (value[0]?.name || change.value + " olarak güncellendi.")
        );
      } catch (err) {
        console.log(err.message);
      }
    },
    modalUpdateFormClean() {
      this.modalUpdateForm = {
        reservationCode: "",
        status: "",
        content: "",
        changes: [],
      };
    },
    async searchComment() {
      try {
        let comment = await axios.get(
          process.env.VUE_APP_API_URL + "admin/comment",
          {
            withCredentials: true,
          }
        );
        this.rowData = comment.data;
        // comment.data.forEach((item, index) => {
        //   let assigns = {}
        //   item.averages.forEach(
        //     (average) => (assigns[average.name] = average.average)
        //   );
        //   this.rowData[index] = { ...item, ...assigns };
        // });

        // let fields = [];
        // comment.data.map((item) =>
        //   item?.averages.map(
        //     (average) =>
        //       !fields.find((i) => i.field == average?.name) &&
        //       fields.push({
        //         field: average.name,
        //         headerName: average.name.toLocaleUpperCase(),
        //         width: 150,
        //       })
        //   )
        // );
        // this.columnDefs.push(...fields);
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: JSON.stringify(error?.response?.data),
        });
      }
    },

    async commentUpdateStatus() {
      try {
        let anotherDatas = {};
        let firstDataFiltered = this.firstData.find(
          (item) => this.modalUpdateForm.reservationCode == item.reservationCode
        );

        firstDataFiltered = JSON.parse(JSON.stringify(firstDataFiltered));
        anotherDatas = JSON.parse(JSON.stringify(this.modalUpdateForm));
        let controller = _.isEqual(firstDataFiltered, this.modalUpdateForm);

        if (!controller) {
          Object.keys(firstDataFiltered).map((key) => {
            const nowThisField = firstDataFiltered[key];
            const anotherField = anotherDatas[key];

            if (
              (typeof nowThisField == "string" ||
                typeof nowThisField == "number" ||
                typeof nowThisField == "boolean") &&
              key !== "updatedAt" &&
              nowThisField !== anotherField
            ) {
              if (!this.modalUpdateForm.changes)
                this.modalUpdateForm.changes = [];

              this.modalUpdateForm.changes.push({
                type: key,
                value: anotherDatas[key],
                user: this.loggedUser,
                changeDateTime: moment().local().format("DD-MM-YYYY HH:mm"),
              });
            }
          });
        }

        await axios
          .put(
            process.env.VUE_APP_API_URL +
              "admin/comment/" +
              this.modalUpdateForm.reservationCode,
            this.modalUpdateForm
          )
          .then((response) => {
            this.commentModalUpdate = false;
            this.searchComment();
          });
      } catch (error) {}
    },

    commentDelete(code) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu Yorumu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/comment/" + code,
                {
                  withCredentials: true,
                }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "Yorum başarılı bir şekilde silindi",
                refresh: false,
              });
              this.searchComment();
              this.commentModalUpdate = false;
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    onRowDoubleClicked(params) {
      this.modalUpdateForm = params.node.data;
      let average = this.modalUpdateForm.average;
      this.modalUpdateForm.average = average;
      this.commentModalUpdate = true;
    },
  },
  watch: {
    commentModalUpdate: function () {
      if (!this.commentModalUpdate) {
        this.modalUpdateFormClean();
      }
    },
  },
};
</script>

<style scoped>
.logs {
  background-color: rgb(241, 241, 241);
  height: 300px;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
}
</style>
