<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            color="#003d6a"
            dark
            dense
            elevation="10"
            style="z-index: 9"
          >
            <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <input
              type="text"
              v-model="searchFilterInput"
              class="form-control"
              style="max-width: 250px"
              autocomplete="new"
              :placeholder="$t('search_t')"
              v-on:keyup="onFilterTextBoxChanged()"
            />
          </v-toolbar>

          <CCardBody class="p-0" v-if="reservationGridShow">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              :class="getClasses()"
              :defaultExcelExportParams="defaultExcelExportParams"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :rowSelection="rowSelection"
              :sideBar="sideBar"
              :enableCharts="true"
              :enableRangeSelection="true"
              :enableBrowserTooltips="true"
              :statusBar="statusBar"
              :animateRows="false"
              @column-moved="onColumnMoved"
              @column-pinned="onColumnMoved"
              @column-visible="onColumnMoved"
              @column-resized="onColumnMoved"
              @column-changed="onColumnMoved"
              @column-row-group-changed="onColumnMoved"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              :getContextMenuItems="getContextMenuItems"
            ></ag-grid-vue>
          </CCardBody>
          <CCardBody class="p-0" v-else>
            <gogo-loading />
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";

import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import GogoLoading from "../../../components/GogoLoading.vue";
import Func from "../../../func";
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);
var _ = require("lodash");
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    cellValue = moment(cellValue).toDate();
    cellValue = cellValue.getTime();
    filterLocalDateAtMidnight = filterLocalDateAtMidnight.getTime();
    if (cellValue == null) return 0;

    if (cellValue < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellValue > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2025,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};
export default {
  name: "ReservationSearch",
  components: {
    AgGridVue,
    GogoLoading,
  },
  data() {
    return {
      searchFilterInput: null,
      reservationGridShow: false,
      defaultExcelExportParams: null,
      columnDefs: [
        {
          field: "status",
          headerName: this.$t("status"),
          enableRowGroup: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRenderer: (params) => {
            var result = "";
            var reservationStatus = params.value;
            if (reservationStatus == "approved") {
              result = this.$t("approved");
            } else if (reservationStatus == "pending") {
              result = this.$t("pending_t");
            } else if (reservationStatus == "active") {
              result = this.$t("active");
            } else if (reservationStatus == "completed") {
              result = this.$t("completed");
            } else if (reservationStatus == "cancelled") {
              result = this.$t("cancelled");
            } else if (reservationStatus == "billed") {
              result = this.$t("billed");
            } else if (reservationStatus == "notbilled") {
              result = this.$t("notbilled");
            } else if (reservationStatus == "canceledbill") {
              result = this.$t("canceledbill");
            }
            // else if (reservationStatus == "earlyReturnRequest") {
            //   result = this.$t("earlyReturnRequest");
            // }
            // else if (
            //   reservationStatus == "earlyReturnFinancialConfirmation"
            // ) {
            //   result = this.$t("earlyReturnRequestWaitConfirm");
            // } else if (reservationStatus == "confirmedEarlyReturn") {
            //   result = this.$t("confirmEarlReturn");
            // }
            else if (reservationStatus == "paymentError") {
              result = this.$t("paymentError");
            } else if (reservationStatus == "noShowCancelled") {
              result = this.$t("noShowCancelled");
            } else {
              result = this.$t("all");
            }

            return result;
          },
          width: 120,
          hide:
            typeof this.$router.history.current.params.reservationStatus !==
            "undefined"
              ? true
              : false,
        },

        {
          field: "statusEarlyReturn",
          valueGetter: (params) => {
            return (
              this.$store.state.ceviri[
                "earlyReturn_" + params?.data?.statusEarlyReturn
              ] || "-"
            );
          },
          headerName: "Erken İade",
          width: 120,
        },
        {
          field: "partnerDetail",
          headerName: "Partner",
          width: 120,
          cellRenderer: (params) => {
            return params.value;
          },
          valueGetter: (params) =>
            (params?.data?.partnerDetail?.title || "") +
            (params?.data?.partnerDetail?.brand ? "/" : "") +
            (params?.data?.partnerDetail?.brand || ""),

          hide: this.isPartner() ? true : false,
        },
        {
          field: "utmSource",
          headerName: "Kaynak",
          width: 120,
          valueGetter: (params) => {
            if (typeof params?.data?.utmSource === "object") {
              return params.data.utmSource.utm_source;
            } else if (typeof params?.data?.utmSource === "string") {
              return params.data.utmSource;
            }
          },
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Medium",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_medium || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Campaign",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_campaign || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Term",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_term || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Content",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_content || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Referans",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.referrer || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "reservationType",
          headerName: this.$t("type"),
          cellRenderer: (params) => {
            return this.$t(params.value);
          },
          width: 100,
        },

        {
          field: "searchPrice.vendor.name",
          headerName: this.$t("vendor"),
          enableRowGroup: true,
          width: 100,
        },
        {
          field: "guest",
          enableRowGroup: true,
          headerName: this.$t("user_type"),
          hide: true,
          width: 120,
          cellRenderer: (params) => {
            switch (params.value) {
              case true:
                return this.$t("quest_member");
              case false:
                return this.$t("registered_member");
              default:
                return this.$t("unknown");
            }
          },
        },

        {
          field: "driverInfo.name",
          headerName: this.$t("name"),
          width: 110,
        },
        {
          field: "driverInfo.lastname",
          headerName: this.$t("lastname"),
          width: 110,
        },

        {
          field: "driverInfo.identity",
          headerName: "TCKN / Pasaport",
          cellRenderer: (params) => {
            const value = params?.value;
            if (value.certificateType === "tc" && value.value)
              return `<b>TC:</b> ${value.value}`;
            else if (value.certificateType === "passport" && value.value)
              return `<b>P:</b> ${value.value}`;
            else return "Girilmemiş";
          },
        },

        {
          field: "reservationCode",
          headerName: this.$t("reservation_number"),
          width: 130,
        },
        {
          field: "reservationCode",
          valueGetter: (params) => {
            return `https://www.renticar.com/gosterge-paneli/rezervasyonlarim/detay/${params?.data?.reservationCode}/${params?.data?.driverInfo.email}#comment`;
          },
          hide: true,
          headerName: this.$t("survey_link"),
          width: 130,
        },
        {
          field: "vendorReservationCode",
          headerName: this.$t("vendor_reservation_number"),
          width: 140,
        },
        {
          field: "rentalDayRange",
          headerName: this.$t("rental_day_range"),
          width: 90,
        },
        {
          field: "driverInfo.flightNo",
          headerName: this.$t("flight_number"),
          width: 110,
          hide: true,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("DD.MM.YYYY");
          },
          valueGetter: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("YYYY-MM-DD");
          },
          headerName: this.$t("reservation_date"),
          width: 100,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("HH:mm");
          },
          valueGetter: (params) => {
            return (
              moment(params.data.reservationDateTime).local().format("HH:mm") ||
              ""
            );
          },
          headerName: this.$t("reservation_hour"),
          width: 90,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("DD.MM.YYYY");
              }
            }
          },
          headerName: this.$t("cancel_date"),
          width: 140,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("HH:mm");
              }
            }
          },
          headerName: this.$t("cancel_time"),
          width: 140,
        },
        {
          field: "refund.place",
          headerName: this.$t("cancel_author"),
          width: 100,
        },
        {
          enableRowGroup: true,
          field: "refund.message",
          valueGetter: (params) => {
            return params?.data?.refund?.message || "";
          },
          headerName: this.$t("reason_for_cancellation"),
          width: 150,
        },
        {
          enableRowGroup: true,
          field: "status",
          headerName: this.$t("error_reason"),
          width: 120,
          valueGetter: (data) => {
            const transactions = data?.data?.bankTransactions || [];
            const lastTransaction = transactions[transactions.length - 1];
            return lastTransaction?.message || "";
          },
        },

        {
          field: "prices.dailyRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.dailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("daily_rental_fee"),
          width: 110,
        },
        {
          field: "prices.retailDailyRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params.data?.prices?.retailDailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailDailyRentalPrice"),
          width: 110,
        },
        !this.isPartner() && {
          field: "rentalCurrencyPrice.currencyCode",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            return currencyCode;
          },
          type: "rightAligned",
          headerName: this.$t("reservation_currency"),
          width: 170,
        },
        !this.isPartner() && {
          field: "rentalCurrencyPrice.vendorDaily",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const vendorDaily = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.vendorDaily}${currencyCode}`
              : `${params.data.prices.retailDailyRentalPrice[currencyCode]}${currencyCode}`;
            return vendorDaily;
          },
          type: "rightAligned",
          headerName: this.$t("supplier_daily_currency_amount"),
          width: 170,
        },

        !this.isPartner() && {
          field: "rentalCurrencyPrice.vendorTotal",
          type: "rightAligned",
          width: 250,
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const vendorTotal = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.vendorTotal}${currencyCode}`
              : `${params.data.prices.retailTotalRentalPrice[currencyCode]}${currencyCode}`;
            return vendorTotal;
          },
          headerName: this.$t("supplier_total_currency_amount"),
          aggFunc: "sum",
        },
        !this.isPartner() && {
          field: "rentalCurrencyPrice.totalDaily",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const totalDaily = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.totalDaily}${currencyCode}`
              : `${params.data.prices.dailyRentalPrice[currencyCode]}${currencyCode}`;
            return totalDaily;
          },
          type: "rightAligned",
          headerName: this.$t("customer_daily_currency_amount"),
          width: 170,
        },
        !this.isPartner() && {
          field: "rentalCurrencyPrice.total",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const total = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.total}${currencyCode}`
              : `${params.data.prices.totalRentalPrice[currencyCode]}${currencyCode}`;
            return total;
          },
          type: "rightAligned",
          headerName: this.$t("customer_total_currency_amount"),
          width: 170,
        },
        {
          field: "currency.forexSelling",
          cellRenderer: (params) => {
            let forexSelling =
              params.data.currency.forexSelling == 1
                ? ""
                : params.data.currency.forexSelling.toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  });
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "İşlem Kuru",
          width: 100,
        },
        {
          field: "searchPrice.totalDays",
          cellRenderer: (params) => {
            return params.value;
          },
          type: "rightAligned",
          headerName: this.$t("rental_days"),
          width: 70,
        },
        {
          field: "prices.totalRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.totalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_payment_price"),
          width: 110,
        },
        {
          field: "prices.retailTotalRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params?.data?.prices?.retailTotalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailTotalRentalPrice"),
          width: 110,
        },
        {
          field: "selectedExtras",
          valueGetter: (params) => {
            return (
              params.data?.prices?.totalExtraPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_extras"),
          width: 110,
        },

        {
          hide: true,
          field: "bankTransactions",
          headerName: this.$t("installment_count"),
          width: 110,
          cellRenderer: (params) => {
            return (
              params.value.find((item) => item.type == "paymentSuccess")?.data
                ?.InstallmentCount || ""
            );
          },
        },
        !this.isPartner() && {
          field: "searchPrice.pricing.vendorDailyPrice",
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorDailyPrice !=
              "object"
            )
              return 0;
            return (
              params.data?.searchPrice?.pricing?.vendorDailyPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: "API " + this.$t("daily_price"),
          width: 140,
        },
        !this.isPartner() && {
          field: "searchPrice.pricing.vendorTotalPrice",
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorTotalPrice !=
              "object"
            )
              return 0;
            return (
              params.data?.searchPrice?.pricing?.vendorTotalPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("vendor_total_rental_price"),
          width: 140,
          hide: this.isPartner() ? true : false,
        },
        {
          field: "posModule",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Pos",
          width: 140,
          hide: true,
        },
        {
          field: "metropolCard",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Metropol Kart",
          width: 140,
          hide: true,
        },
        {
          field: "orderId",
          headerName: this.$t("order_number"),
          width: 300,
          hide: true,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "green" },
          hide: true,
          valueGetter: (params) => {
            if (params?.data.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                return Number(Func.toMoneyFormat(sonuc));
              }
            } else {
              return 0;
            }
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            });
          },
          headerName: this.$t("amount_paid"),
          width: 140,
        },
        {
          field: "payments",
          hide: true,
          type: "rightAligned",
          valueGetter: (params) => {
            if (params?.data?.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                var result = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                if (params.data?.prices?.totalPrice?.TRY) {
                  result = sonuc - params.data?.prices?.totalPrice.TRY;
                  return Number(Func.toMoneyFormat(sonuc)).toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "TRY",
                    }
                  );
                }
              }
            }
            return 0;
          },
          headerName: this.$t("interest"),
          width: 140,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "red" },
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorTotalPrice?.TRY !==
              "undefined"
            ) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "refund"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc = sonuc + sayi.totalAmount;
                });
                return sonuc.toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                });
              }
            }
            return 0;
          },
          headerName: this.$t("returned"),
          hide: true,
          width: 140,
        },
        {
          field: "carDeliveryReturn.delivery.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("purchase_date"),
          width: 140,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },
        {
          field: "carDeliveryReturn.return.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("returned_date"),
          width: 140,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },

        {
          field: "ERP.invoiceTransferStatus",
          cellRenderer: (params) => {
            return params.value == "ok"
              ? this.$t("invoiced")
              : params.value == "pending"
              ? this.$t("pending")
              : params.value == "error"
              ? this.$t("error_t")
              : "";
          },
          headerName: this.$t("invoice_status"),
          width: 140,
          hide: true,
        },
        {
          field: "ERP.invoiceDate",
          cellRenderer: (params) => {
            return params.value
              ? moment(params.value).format("DD.MM.YYYY")
              : "";
          },
          headerName: this.$t("invoice_date"),
          width: 140,
          hide: true,
        },
        {
          field: "ERP.invoiceNumber",
          headerName: this.$t("invoice_number"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData",
          valueGetter: (params) => {
            return params.data.searchPrice.campaignData != null
              ? this.$t("yes")
              : this.$t("not");
          },
          headerName: this.$t("with_a_campaign"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData.info.name.tr",
          headerName: this.$t("campaign_name"),
          valueGetter: (params) => {
            const data = params.data.searchPrice;
            if (data?.campaignData?.info?.name?.tr) {
              return data.campaignData.info.name.tr;
            } else if (data?.campaignData?.info?.name) {
              return data.campaignData.info.name;
            } else {
              return "";
            }
          },
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountRate",
          headerName: this.$t("campaign_discount_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountAmount",
          headerName: this.$t("campaign_discount_price"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.vendorCommission",
          headerName: this.$t("campaign_vendor_commision_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("delivery_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.return.name",
          headerName: this.$t("return_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("differen_returns"),
          width: 110,
          valueGetter: (params) => {
            return params.data.carDeliveryReturn.return.name !=
              params.data.carDeliveryReturn.delivery.name
              ? this.$t("yes")
              : this.$t("not");
          },
          hide: true,
        },
        {
          field: "prices.dropPrice",
          headerName: this.$t("different_return_amount"),
          type: "rightAligned",
          width: 140,
          valueGetter: (params) => {
            return (
              params.data?.prices?.dropPrice[this.$store.state.currency] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params?.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
        },
        {
          field: "searchPrice.car.model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "searchPrice.car.brand",
          headerName: this.$t("brand"),
          width: 100,
        },
        {
          field: "searchPrice.car.class",
          headerName: "Araç Sınıfı",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },
          width: 100,
        },
        {
          field: "userType",
          valueGetter: (params) => {
            if (params.data?.modifications[0].departmanRole) {
              return (
                this.$store.state.ceviri[
                  params.data.modifications[0].departmanRole
                ]
              );
            } else {
              return this.$store.state.ceviri[
                params.data.modifications[0].userType
              ];
            }
          },
          headerName: this.$t("operating_user_type"),
          width: 160,
          hide: true,
        },

        {
          field: "driverInfo.email",
          headerName: this.$t("driver_email"),
          width: 200,
        },
        {
          field: "driverInfo.phone",
          headerName: this.$t("driver_phone"),
          width: 130,
          hide: true,
        },
        {
          field: "spendingObjection",
          headerName: this.$t("spending_objection"),
          width: 130,
          cellRenderer: ({ data: { spendingObjection } }) =>
            spendingObjection ? this.$t("there_is") : this.$t("none"),
          valueGetter: ({ data: { spendingObjection } }) =>
            spendingObjection ? this.$t("there_is") : this.$t("none"),
          hide: true,
        },
        {
          field: "reservationBy",
          headerName: this.$t("trader"),
          width: 150,
          hide: true,
          valueGetter: (params) => {
            return params.data.modifications.find(
              (item) => item.change == "created"
            ).fullname;
          },
          cellRenderer: (params) => {
            return params.data.modifications.find(
              (item) => item.change == "created"
            ).fullname;
          },
        },
        {
          field: "paymentType",
          valueGetter: (params) => {
            let type = params?.data?.paymentType
              ? this.$store.state.ceviri[params.data.paymentType]
              : "";
            return type;
          },
          headerName: this.$t("payment_type"),
          width: 110,
        },
        {
          field: "driverInfo.permissions",
          headerName: "Arama İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.driverInfo?.permissions;
            return phonePermission && phonePermission.includes("phone")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "driverInfo.permissions",
          headerName: "SMS İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.driverInfo?.permissions;
            return phonePermission && phonePermission.includes("sms")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "driverInfo.permissions",
          headerName: "Email İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.driverInfo?.permissions;
            return phonePermission && phonePermission.includes("email")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "isComment.average",
          headerName: this.$t("comment_average"),
          width: 150,
        },
        {
          field: "pollCounter",
          headerName: this.$t("is_comment_sent"),
          width: 170,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: this.$t("columns"),
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false,
            },
          },
          {
            id: "filters",
            labelDefault: this.$t("filters"),
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple",

      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      searchReservationCollapsed: false,
      searchReservationForm: {
        reservationCode: "",
        vendorSlug: "",
        orderId: "",
        statusEarlyReturn: "",
        delivery: {
          locationSlug: "",
          date: {
            start: "",
            end: "",
          },
        },
        return: {
          locationSlug: "",
          date: {
            start: "",
            end: "",
          },
        },
        driver: {
          name: "",
          lastname: "",
          email: "",
          phone: "",
        },
        identity: {
          certificateType: "tumu", // Aldığı Değerler tumu/t c/passport.
          value: "",
        },
      },
    };
  },

  created() {
    this.searchReservation();
    this.defaultExcelExportParams = {
      processCellCallback: (params) => {
        if (
          params.column.colDef.headerName === "Rezervasyon Tarihi" &&
          params.value
        ) {
          return moment(params.value).local().format("DD.MM.YYYY");
        } else if (
          params.column.colDef.headerName === "Taksit Sayısı" &&
          params.value
        ) {
          return (
            params.value.find((item) => item.type == "paymentSuccess")?.data
              ?.InstallmentCount || ""
          );
        } else if (
          params.column.colDef.headerName === this.$t("campaign_name")
        ) {
          const data = params?.node?.data?.searchPrice;
          if (data?.campaignData?.info?.name?.tr) {
            return data.campaignData.info.name.tr;
          } else if (data?.campaignData?.info?.name) {
            return data.campaignData.info.name;
          } else {
            return "";
          }
        } else if (
          params.column.colDef.headerName === "Fatura Tarihi" &&
          params.value
        ) {
          return moment(params.value).format("DD.MM.YYYY");
        } else if (
          params.column.colDef.headerName === "Rezervasyon Saati" &&
          params.value
        ) {
          return moment(params.value).local().format("HH:mm");
        } else if (
          params.column.colDef.headerName === "Alış Tarihi" &&
          params.value
        ) {
          return moment(params.value).format("DD.MM.YYYY HH:mm");
        } else if (
          params.column.colDef.headerName === "İade Tarihi" &&
          params.value
        ) {
          return moment(params.value).format("DD.MM.YYYY HH:mm");
        } else if (
          params.column.colDef.headerName === "TCKN / Pasaport" &&
          params.value
        ) {
          const value = params?.value;
          if (value.certificateType === "tc" && value.value)
            return `TC: ${value.value}`;
          else if (value.certificateType === "passport" && value.value)
            return `P: ${value.value}`;
          else return "Girilmemiş";
        } else if (
          params.column.colDef.headerName === "İşlem Yapan" &&
          params.value
        ) {
          return params.value;
        } else if (
          params.column.colDef.headerName === "Uçuş Numarası" &&
          params.value
        ) {
          return params.value;
        } else if (
          params.column.colDef.headerName === "Rezervasyon Para Birimi"
        ) {
          const currencyType =
            params?.node?.data?.rentalCurrencyPrice?.currencyCode || "TRY";
          return currencyType;
        } else if (
          params.column.colDef.headerName === "Müşteri Toplam Döviz Tutarı"
        ) {
          params.value
            ? (params.value = Number(params.value.toString().replace(",", ".")))
            : 0;
          return params.value;
        } else if (params.column.colDef.headerName === "İşlem Kuru") {
          params.value
            ? (params.value = Number(params.value.toString().replace(",", ".")))
            : 0;
          return params.value;
        } else if (
          params.column.colDef.headerName === "Müşteri Günlük Döviz Tutarı"
        ) {
          params.value
            ? (params.value = Number(params.value.toString().replace(",", ".")))
            : 0;
          return params.value;
        } else if (
          params.column.colDef.headerName === "Tedarikçi Günlük Döviz Tutarı"
        ) {
          params.value
            ? (params.value = Number(params.value.toString().replace(",", ".")))
            : 0;
          return params.value;
        } else if (
          params.column.colDef.headerName === "Tedarikçi Toplam Döviz Tutar"
        ) {
          params.value
            ? (params.value = Number(params.value.toString().replace(",", ".")))
            : 0;
          return params.value;
        } else if (
          params.column.colDef.headerName === "Kiralama Tutarı (Ödenen)" &&
          params.value
        ) {
          if (params.value?.length > 0) {
            var deger = params.value[params.value?.length - 1].data.TotalAmount;
            if (typeof deger === "undefined") {
              return 0;
            } else {
              return deger;
            }
          }
          return 0;
        } else if (
          params.column.colDef.headerName === "Partner" &&
          params.value
        ) {
          return params?.value || "";
        } else if (
          params.column.colDef.headerName === "İptal Tarihi" &&
          params.value
        ) {
          return params?.value;
        } else {
          return params.value;
        }
      },
    };
  },
  watch: {
    "searchReservationForm.delivery.date": function () {
      if (this.searchReservationForm.delivery.date === null) {
        this.searchReservationForm.delivery.date = {
          start: "",
          end: "",
        };
      }
    },
    "searchReservationForm.return.date": function () {
      if (this.searchReservationForm.return.date === null) {
        this.searchReservationForm.return.date = {
          start: "",
          end: "",
        };
      }
    },
    "$i18n.locale": function (val, oldVal) {
      window.location.reload();
    },
  },
  computed: {
    pageTitle() {
      var result = "";
      var reservationStatus =
        this.$router.history.current.params.reservationStatus;
      if (reservationStatus == "approved") {
        result = this.$t("future_reservations");
      } else if (reservationStatus == "pending") {
        result = this.$t("sidebar.recent_searches");
      } else if (reservationStatus == "active") {
        result = this.$t("ongoing_reservations");
      } else if (reservationStatus == "completed") {
        result = this.$t("past_reservations");
      } else if (reservationStatus == "cancelled") {
        result = this.$t("canceled_reservations");
      } else if (reservationStatus == "billed") {
        result = this.$t("invoiced_reservations");
      } else if (reservationStatus == "notbilled") {
        result = this.$t("not_invoiced_reservations");
      } else if (reservationStatus == "canceledbill") {
        result = this.$t("invoiced_cancel_reservations");
      }
      // else if (reservationStatus == "earlyReturnRequest") {
      //   result = this.$t("early_return_requests");
      // }
      else if (reservationStatus == "earlyReturnFinancialConfirmation") {
        result = this.$t("early_return_awaiting_approval");
      } else if (reservationStatus == "confirmedEarlyReturn") {
        result = this.$t("early_return_success");
      } else if (reservationStatus == "paymentError") {
        result = this.$t("paymentError");
      } else if (reservationStatus == "noShowCancelled") {
        result = this.$t("noShowCancelled");
      } else {
        result = this.$t("all");
      }

      return result;
    },
  },
  methods: {
    //RentalCurrenyPrice bazen null bazende hiç gelmediği için bu şekilde bir kontrol yaptım.
    checkRentalCurrency(params) {
      const rentalCurrencyData = params?.data?.rentalCurrencyPrice;
      let check =
        rentalCurrencyData !== null &&
        rentalCurrencyData &&
        rentalCurrencyData.currencyCode !== ""
          ? true
          : false;
      return check;
    },
    //Döviz ile alakalı bütün stunlarda para birimini kullandığım için kodları kısaltmak için bu fonksiyonu yazdım.
    selectCurrencyCode(params) {
      const currencycode = this.checkRentalCurrency(params)
        ? params?.data?.rentalCurrencyPrice?.currencyCode
        : params?.data?.currency?.currencyCode;
      return currencycode;
    },
    isPartner() {
      return this.$store.state.GetServerData?.userType == "partner";
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    getContextMenuItems(params) {
      var _this = this;
      var selectedRowCount = params.api.getSelectedRows()?.length;
      var result = [
        {
          name:
            this.$t("condition_partner_paid") +
            " (" +
            selectedRowCount +
            this.$t("reservation") +
            ")",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();

            alert(this.$t("coming_soon"));
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        "copy",
        "export",
      ];
      return result;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var columnState = JSON.parse(localStorage.getItem("gridReservations"));
      if (columnState) {
        params.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },

    onColumnMoved(params) {
      var columnState = JSON.stringify(params.columnApi.getColumnState());
      localStorage.setItem("gridReservations", columnState);
    },

    getClasses() {
      //
      let classes = {
        "ag-theme-balham": true,
      };
      if (!this.searchReservationCollapsed) {
        classes["fp-table"] = true;
      } else {
        classes["fp-table-open"] = true;
      }
      return classes;
    },

    onRowDoubleClicked(params) {
      let routeData = this.$router.resolve({
        name: "Rezervasyon Detayı",
        params: {
          reservationCode: params.node.data.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },

    searchReservation() {
      this.$Progress.start();
      const path_name = window.location.pathname;
      const verb = path_name.split("/").pop();


      if (verb === "earlyReturnFinancialConfirmation") {
        this.searchReservationForm.statusEarlyReturn = "pending";
      } else if (verb === "confirmedEarlyReturn") {
        this.searchReservationForm.statusEarlyReturn = "approved";
      }
      this.searchReservationForm.status = verb;

      if (this.$store.state.GetServerData.role == "PARTNER") {
        this.searchReservationForm.partnerID =
          this.$store.state.GetServerData.partnerID;
      }
      let query = {
        ...this.searchReservationForm,
        ...this.$route.query,
      };
      axios
        .get(process.env.VUE_APP_API_URL + "admin/reservation", {
          params: query,
        })
        .then((response) => {
          if (response.data.errCode) {
            this.rowData = [];
          } else {
            this.rowData = response.data.reservations.map((item) => ({
              ...item,
              posModule: item?.payments?.length
                ? item.payments[0].posModule
                : "iyzico",
            }));
          }
          this.searchReservationCollapsed = false;
          this.reservationGridShow = true;
        })
        .catch()
        .finally(() => this.$Progress.finish());
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer !important;
}

.input-group-radio {
  input {
    &:hover {
      cursor: pointer !important;
    }
  }

  label {
    &:hover {
      cursor: pointer !important;
    }
  }
}
</style>
