<template>
  <div>
    <div>
      <v-row>
        <v-col cols="12" sm="12">
          <v-row>
            <CModal
              size="lg"
              :title="$t('billing_information')"
              :show.sync="userPermissionUpdate"
              :closeOnBackdrop="false"
            >
              <v-row>
                <v-col cols="12" md="7">
                  <v-toolbar-title style="font-size: 20px; color: #0c3c5e"
                    ><strong> Bizi Nereden Duydunuz </strong></v-toolbar-title
                  >
                  <v-col cols="10">
                    <v-select
                      :clearable="false"
                      v-model="userModalAddUpdate.form.whereDidYouHear"
                      :reduce="(item) => item"
                      :options="whereDidYouHearJSON"
                      placeholder="Bizi Nereden Duydunuz"
                      label="name"
                      id="id"
                  /></v-col>
                </v-col>
                <v-col cols="12" md="5">
                  <ul class="pl-3">
                    <li class="mt-2">
                      <a
                        href="https://www.renticar.com/sozlesmeler/ticari-ileti"
                        target="_blank"
                      >
                        <del v-if="!userModalAddUpdate.form.consent">
                          Önemli bildirimler ve kampanyalardan haberdar olmak
                          için
                          <strong>Ticari İleti</strong> onayı
                        </del>
                        <ins v-else>
                          Önemli bildirimler ve kampanyalardan haberdar olmak
                          için
                          <strong>Ticari İleti</strong> onayı
                        </ins>
                      </a>
                    </li>

                    <li class="mt-2 list-unstyled">
                      <CInputCheckbox
                        v-for="(option, optKey) in userModalAddUpdate.fields
                          .permissions.options"
                        :key="option + optKey"
                        :inline="true"
                        :label="option.label"
                        :value="option.value"
                        :checked="
                          userModalAddUpdate.form.permissions.some(
                            (item) => item === option.value
                          )
                        "
                        @update:checked="
                          updatePermissionsCheckBox(option.value)
                        "
                      />
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <template #footer>
                <CButton
                  color="success text-white"
                  :disabled="userModalAddUpdate.btnDisable"
                  @click="userPermsUpdate()"
                  >Güncelle</CButton
                >
              </template>
            </CModal>
            <CModal
              size="lg"
              :title="$t('driver_info')"
              :show.sync="userInfoUpdate"
            >
              <v-form ref="driverInfoForm">
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      outlined
                      dense
                      :label="$t('name')"
                      v-model="userModalAddUpdate.form.name"
                      :rules="[rules.required]"
                      @input="
                        userModalAddUpdate.form.name =
                          userModalAddUpdate.form.name.turkishToUpper()
                      "
                    />
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      outlined
                      dense
                      :label="$t('lastname')"
                      :rules="[rules.required]"
                      v-model="userModalAddUpdate.form.lastname"
                      @input="
                        userModalAddUpdate.form.lastname =
                          userModalAddUpdate.form.lastname.turkishToUpper()
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      outlined
                      dense
                      :label="$t('phone')"
                      v-model="userModalAddUpdate.form.phone"
                      :rules="[rules.phone]"
                    />
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      outlined
                      dense
                      :label="$t('email')"
                      v-model="userModalAddUpdate.form.email"
                      :rules="[rules.email]"
                    />
                  </v-col>
                </v-row>
                <v-row
                  ><v-col sm="6">
                    <v-autocomplete
                      outlined
                      dense
                      :items="driverIdentityValue"
                      item-text="tr"
                      item-value="value"
                      :label="$t('identity_type')"
                      @input="driverIdentityValue"
                      v-model="userModalAddUpdate.form.defaultIdType"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    v-if="userModalAddUpdate.form.defaultIdType === 0"
                  >
                    <v-text-field
                      outlined
                      dense
                      :label="$t('tc_identity')"
                      v-model="userModalAddUpdate.form.tcId"
                      :rules="[rules.identity]"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    v-if="userModalAddUpdate.form.defaultIdType === 1"
                  >
                    <v-text-field
                      outlined
                      dense
                      :label="$t('passport')"
                      v-model="userModalAddUpdate.form.passportId"
                      :rules="[rules.identity]"
                    />
                  </v-col>

                  <v-col cols="12">
                    <label
                      class="font-weight-bold"
                      v-html="$t('date_of_birth')"
                    />
                    <imask-input
                      class="form-control"
                      v-model="userModalAddUpdate.form.birthday"
                      :mask="Date"
                      :lazy="false"
                      radix="."
                      :unmask="false"
                      :placeholder="$t('date_of_birth')"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <template #footer>
                <v-btn @click="userUpdate" class="primary" small
                  >Güncelle</v-btn
                >
              </template>
            </CModal>
            <CModal
              size="lg"
              :title="$t('billing_information')"
              :show.sync="userInvoiceUpdate"
            >
              <v-row>
                <v-col sm="12">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="userModalAddUpdate.form.invoices.invoiceType"
                    :items="invoiceTypeValue"
                    item-text="tr"
                    item-val="value"
                    :label="$t('contract')"
                    @input="updateInvoiceType"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-form ref="individual">
                <v-row
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType ===
                    'individual'
                  "
                >
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required]"
                      :label="$t('city')"
                      v-model="
                        userModalAddUpdate.form.invoices.individual.province
                      "
                    />
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required]"
                      :label="$t('post_code')"
                      v-model="
                        userModalAddUpdate.form.invoices.individual.postCode
                      "
                    />
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required]"
                      :label="$t('address')"
                      v-model="
                        userModalAddUpdate.form.invoices.individual.address
                      "
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-form ref="individualCompany">
                <v-row
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType ===
                    'individualCompany'
                  "
                >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('name')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .firstname
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('lastname')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .lastname
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('tax_office')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .taxOffice
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        min="10"
                        max="11"
                        v-mask="'###########'"
                        :rules="[rules.required]"
                        :label="$t('tax_number')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .taxNumber
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('city')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .province
                        "
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('post_code')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .postCode
                        "
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('address')"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .address
                        "
                      />
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
              <v-form ref="company">
                <v-row
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType === 'company'
                  "
                >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('title')"
                        v-model="userModalAddUpdate.form.invoices.company.title"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('tax_office')"
                        v-model="
                          userModalAddUpdate.form.invoices.company.taxOffice
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('tax_number')"
                        v-model="
                          userModalAddUpdate.form.invoices.company.taxNumber
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('city')"
                        v-model="
                          userModalAddUpdate.form.invoices.company.province
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('post_code')"
                        v-model="
                          userModalAddUpdate.form.invoices.company.postCode
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('address')"
                        v-model="
                          userModalAddUpdate.form.invoices.company.address
                        "
                      />
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
              <v-form ref="abroad">
                <v-row
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType === 'abroad'
                  "
                >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('title')"
                        v-model="userModalAddUpdate.form.invoices.abroad.title"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('country')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.country
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('city')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.province
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('address')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.address
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('tax_office')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.taxOffice
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('tax_number')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.taxNumber
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('post_code')"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.postCode
                        "
                      />
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
              <template #footer>
                <v-btn @click="invoicesUpdate" class="primary" small
                  >Güncelle</v-btn
                >
              </template>
            </CModal>
            <CModal
              size="lg"
              :title="'Kupon Ekle'"
              :show.sync="userCouponAdd"
              :closeOnBackdrop="false"
            >
              <CRow>
                <CCol col="6">
                  <v-select
                    :clearable="false"
                    v-model="couponsName"
                    :reduce="(item) => item"
                    :options="couponsJSON"
                    placeholder="Seçiniz"
                    label="name"
                    id="_id"
                /></CCol>
                <CCol col="6">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    placeholder="Kupon Adet"
                    v-model.number="couponsNumber"
                  ></v-text-field>
                </CCol>
              </CRow>
              <template #footer>
                <CButton
                  color="info text-white font-weight-bold "
                  @click="couponsUserAdd"
                  >Oluştur</CButton
                >
              </template>
            </CModal>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card elevation="10">
                    <v-toolbar
                      height="40"
                      dense
                      dark
                      style="background-color: #0c3c5e"
                      elevation="0"
                      class="flex justify-between"
                    >
                      <v-toolbar-title class="flex" style="font-size: 14px"
                        ><strong> Üye Bilgileri </strong>
                      </v-toolbar-title>
                      <RoleProvider slug="USER_UPDATE">
                        <template slot-scope="{ canItPass }">
                          <v-toolbar-title
                            style="display: flex; float: right"
                            class="flex justify-end"
                          >
                            <v-btn
                              v-if="canItPass"
                              color="green lighten-1"
                              x-small
                              @click="userInfoUpdate = true"
                              >Güncelle</v-btn
                            >
                          </v-toolbar-title></template
                        >
                      </RoleProvider>
                    </v-toolbar>
                    <div>
                      <v-row>
                        <v-col col="12" class="pa-0">
                          <v-simple-table dense>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>
                                    {{ $t("name") }}
                                    {{ $t("lastname") }}
                                  </strong>
                                </td>
                                <td>
                                  {{ userModalAddUpdate.form.name }}
                                  {{ userModalAddUpdate.form.lastname }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong> {{ $t("phone") }} </strong>
                                </td>
                                <td>{{ userModalAddUpdate.form.phone }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {{ $t("email") }}
                                  </strong>
                                </td>
                                <td>{{ userModalAddUpdate.form.email }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {{ $t("identity") }}
                                  </strong>
                                </td>
                                <td>
                                  {{
                                    userModalAddUpdate.form.defaultIdType == 0
                                      ? "T.C. No."
                                      : "Pasaport No."
                                  }}
                                  /
                                  {{
                                    userModalAddUpdate.form.defaultIdType == 0
                                      ? userModalAddUpdate.form.tcId
                                      : userModalAddUpdate.form.passportId
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {{ $t("date_of_birth") }}
                                  </strong>
                                </td>
                                <td>
                                  {{ userModalAddUpdate.form.birthday }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" class="mt-6">
                  <v-card elevation="10">
                    <v-toolbar
                      height="40"
                      dense
                      dark
                      style="background-color: #0c3c5e"
                      elevation="0"
                      class="flex justify-between"
                    >
                      <v-toolbar-title class="flex" style="font-size: 14px"
                        ><strong> İletişim Bilgileri </strong>
                      </v-toolbar-title>
                      <RoleProvider slug="USER_UPDATE">
                        <template slot-scope="{ canItPass }">
                          <v-toolbar-title
                            style="display: flex; float: right"
                            class="flex justify-end"
                          >
                            <v-btn
                              v-if="canItPass"
                              color="green lighten-1"
                              x-small
                              @click="userPermissionUpdate = true"
                              >Güncelle</v-btn
                            >
                          </v-toolbar-title></template
                        >
                      </RoleProvider>
                    </v-toolbar>
                    <div>
                      <v-row>
                        <v-col col="12" class="pa-0">
                          <v-simple-table dense>
                            <tbody>
                              <tr>
                                <td>
                                  <strong> Bizi Nereden Duydunuz </strong>
                                </td>
                                <td>
                                  {{ userModalAddUpdate.form.whereDidYouHear }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong> {{ $t("phone") }} İzni </strong>
                                </td>
                                <td
                                  :class="
                                    userModalAddUpdate.form.permissions.includes(
                                      'phone'
                                    )
                                      ? 'success--text'
                                      : 'red--text'
                                  "
                                >
                                  {{
                                    userModalAddUpdate.form.permissions.includes(
                                      "phone"
                                    )
                                      ? "Onay"
                                      : "Red"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong> {{ $t("email") }} İzni </strong>
                                </td>
                                <td
                                  :class="
                                    userModalAddUpdate.form.permissions.includes(
                                      'email'
                                    )
                                      ? 'success--text'
                                      : 'red--text'
                                  "
                                >
                                  {{
                                    userModalAddUpdate.form.permissions.includes(
                                      "email"
                                    )
                                      ? "Onay"
                                      : "Red"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong> SMS İzni</strong>
                                </td>
                                <td
                                  :class="
                                    userModalAddUpdate.form.permissions.includes(
                                      'sms'
                                    )
                                      ? 'success--text'
                                      : 'red--text'
                                  "
                                >
                                  {{
                                    userModalAddUpdate.form.permissions.includes(
                                      "sms"
                                    )
                                      ? "Onay"
                                      : "Red"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5">
              <v-card elevation="10" class="">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  style="background-color: #0c3c5e"
                  elevation="0"
                  class="flex justify-between"
                >
                  <v-toolbar-title class="flex" style="font-size: 14px"
                    ><strong>
                      {{ $t("billing_information") }}
                    </strong></v-toolbar-title
                  >
                  <RoleProvider slug="USER_UPDATE">
                    <template slot-scope="{ canItPass }">
                      <v-toolbar-title
                        style="display: flex; float: right"
                        class="flex justify-end"
                      >
                        <v-btn
                          v-if="canItPass"
                          color="green lighten-1"
                          v-model="userInvoiceUpdate"
                          @click="userInvoiceUpdate = true"
                          x-small
                          >Güncelle</v-btn
                        >
                      </v-toolbar-title></template
                    ></RoleProvider
                  >
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody
                          v-if="
                            userModalAddUpdate.form.invoices.invoiceType ===
                            'individual'
                          "
                        >
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(userModalAddUpdate.form.invoices.invoiceType)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("province") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.individual
                                    .province
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("post_code") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.individual
                                    .postCode
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.individual
                                    .address
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            userModalAddUpdate.form.invoices.invoiceType ===
                            'individualCompany'
                          "
                        >
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(userModalAddUpdate.form.invoices.invoiceType)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("name") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.firstname
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("lastname") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.lastname
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("tax_office") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.taxOffice
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("tax_number") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.taxNumber
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("province") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.province
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("post_code") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.postCode
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices
                                    .individualCompany.address
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            userModalAddUpdate.form.invoices.invoiceType ===
                            'company'
                          "
                        >
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(userModalAddUpdate.form.invoices.invoiceType)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("title") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company.title
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("tax_office") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company
                                    .taxOffice
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("tax_number") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company
                                    .taxNumber
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("province") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company
                                    .province
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("post_code") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company
                                    .postCode
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.company
                                    .address
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            userModalAddUpdate.form.invoices.invoiceType ===
                            'abroad'
                          "
                        >
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(userModalAddUpdate.form.invoices.invoiceType)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("title") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad.title
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("name") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .firstname
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("lastname") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .lastname
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("passport") + " No" }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .passportNumber
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("tax_number") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .taxNumber
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("country") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .country
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("province") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .province
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("post_code") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .postCode
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $t(
                                  userModalAddUpdate.form.invoices.abroad
                                    .address
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card elevation="10" class="mb-5">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  style="background-color: #0c3c5e"
                  elevation="0"
                  class="flex justify-between"
                >
                  <v-toolbar-title class="flex" style="font-size: 14px"
                    ><strong> İşlemler </strong>
                  </v-toolbar-title>
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col cols="12" class="pa-4">
                      <RoleProvider slug="USER_UPDATE">
                        <div slot-scope="{ canItPass }">
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            style="background-color: #0c3c5e"
                            small
                            color="text-white font-weight-bold"
                            v-if="canItPass"
                            @click="
                              userPasswordReset(userModalAddUpdate.form._id)
                            "
                          >
                            Yeni Şifre Oluştur
                          </v-btn>
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="USER_UPDATE">
                        <div slot-scope="{ canItPass }" class="mt-3">
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            small
                            style="background-color: #0c3c5e"
                            color="text-white font-weight-bold"
                            v-if="
                              userModalAddUpdate.form.status != 'Active' &&
                              canItPass
                            "
                            @click="
                              userChangeStatus(
                                userModalAddUpdate.form._id,
                                'Active'
                              )
                            "
                            >Kullanıcıyı Aktif Yap</v-btn
                          >
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="USER_UPDATE">
                        <div slot-scope="{ canItPass }" class="mt-3">
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            small
                            style="background-color: #0c3c5e"
                            color="text-white font-weight-bold"
                            v-if="canItPass"
                            @click="
                              (couponModalShow = true)(
                                (userModalAddUpdate.modal = false)
                              )
                            "
                            >Kullanıcıya Kupon Ata</v-btn
                          >
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="USER_UPDATE">
                        <div slot-scope="{ canItPass }" class="mt-3">
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            small
                            v-if="
                              userModalAddUpdate.form.status != 'InActive' &&
                              canItPass
                            "
                            @click="
                              userChangeStatus(
                                userModalAddUpdate.form._id,
                                'InActive'
                              )
                            "
                            style="background-color: #0c3c5e"
                            color="text-white font-weight-bold"
                            >Kullanıcıyı Pasif Yap</v-btn
                          >
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="USER_UPDATE">
                        <div slot-scope="{ canItPass }" class="mt-3">
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            small
                            v-if="
                              userModalAddUpdate.form.status != 'Banned' &&
                              canItPass
                            "
                            @click="
                              userChangeStatus(
                                userModalAddUpdate.form._id,
                                'Banned'
                              )
                            "
                            color="deep-orange accent-4 font-weight-bold white--text"
                            >Kullanıcıyı Blokla</v-btn
                          >
                        </div>
                      </RoleProvider>
                      <RoleProvider
                        v-if="
                          $store.state.GetServerData.departmanRole ===
                          'CustomerExperience'
                        "
                        slug="USER_DELETE"
                      >
                        <div
                          class="mt-3"
                          slot-scope="{ canItPass }"
                          v-if="
                            $store.state.GetServerData.departmanRole ===
                            'CustomerExperience'
                          "
                        >
                          <v-btn
                            class="mb-1 text-capitalize subtitle-2 py-4"
                            block
                            small
                            v-if="canItPass"
                            color="deep-orange accent-4 font-weight-bold white--text"
                            @click="userDelete(userModalAddUpdate.form._id)"
                            >Kullanıcıyı Sil</v-btn
                          >
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="USER_DELETE">
                        <div slot-scope="{ canItPass }" class="mt-3">
                          <v-btn
                            class="mb-2 text-capitalize h6 font-weight-bold py-4"
                            small
                            color="grey lighten-1"
                            v-if="canItPass"
                            @click="deleteAccount()"
                            block
                            >Kullanıcı Üyeliğini İptal Et</v-btn
                          >
                        </div>
                      </RoleProvider>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-toolbar style="background-color: #0c3c5e" dense dark flat>
                  <template>
                    <v-tabs v-model="tab">
                      <v-tab
                        v-for="(item, i) in tabItem"
                        class="text-capitalize"
                      >
                        {{ item.title }}
                      </v-tab>
                    </v-tabs>
                  </template>
                </v-toolbar>

                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="(item, i) in tabItem">
                    <v-card v-if="i === 0">
                      <v-row>
                        <v-col cols="12">
                          <v-card flat color="white">
                            <div
                              v-if="userModalAddUpdate.process == 'put'"
                              class="d-flex justify-content-between"
                              style="width: 100%"
                            >
                              <div class="d-flex w-100">
                                <RoleProvider slug="ADMIN_NOTE_FIND">
                                  <div slot-scope="{ canItPass }" class="w-100">
                                    <AgentNote
                                      :_id="userModalAddUpdate.form._id"
                                      :slug="'members'"
                                      :total="agentNotesTotal"
                                      v-if="canItPass"
                                    />
                                  </div>
                                </RoleProvider>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-card flat v-if="i === 1">
                      <ag-grid-vue
                        style="width: 100%; height: calc(100vh - 450px)"
                        :class="getClasses()"
                        :columnDefs="columnDefs"
                        :defaultColDef="defaultColDef"
                        :rowData="tabItem[1].data"
                        :rowSelection="rowSelection"
                        :sideBar="sideBar"
                        :enableCharts="true"
                        :enableRangeSelection="true"
                        :enableBrowserTooltips="true"
                        :statusBar="statusBar"
                        :animateRows="false"
                        @column-moved="onColumnMoved"
                        @column-pinned="onColumnMoved"
                        @column-visible="onColumnMoved"
                        @column-resized="onColumnMoved"
                        @column-changed="onColumnMoved"
                        @column-row-group-changed="onColumnMoved"
                        @rowDoubleClicked="onRowDoubleClicked"
                        @grid-ready="onGridReady"
                      ></ag-grid-vue>
                    </v-card>
                    <v-card v-if="i === 2">
                      <div>
                        <v-row>
                          <v-col col="12" class="pa-0">
                            <v-simple-table dense>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong> Kupon Adı </strong>
                                  </td>
                                  <td >
                                    <strong> Kodu </strong>
                                  </td>
                                  <td >
                                    <strong> Kalan Kullanım Hakkı </strong>
                                  </td>
                                  <td>
                                    <strong> Tarih </strong>
                                  </td>
                                  <td >
                                    <RoleProvider slug="RESERVATION_UPDATE">
                                      <template slot-scope="{ canItPass }">
                                        <v-toolbar-title>
                                          <v-btn
                                            v-if="canItPass"
                                            color="green lighten-1"
                                            class="text-white"
                                            x-small
                                            block
                                            @click="userCouponAdd = true"
                                            >Kupon Ekle</v-btn
                                          >
                                        </v-toolbar-title></template
                                      >
                                    </RoleProvider>
                                  </td>
                                </tr>
                                <tr
                                  v-if="tabItem[2].data.length > 0"
                                  v-for="data in tabItem[2].data"
                                  :key="data._id"
                                >
                                  <td >
                                    {{ data.couponId.name }}
                                  </td>
                                  <td >
                                    {{ data.code }}
                                  </td>
                                  <td >
                                    {{ data.remaining }} Adet
                                  </td>
                                  <td >
                                    {{ data.updatedAt || '' }}
                                  </td>
                                  <td >
                                    <v-btn
                                      color="red"
                                      class="text-white"
                                      x-small
                                      block
                                      @click="
                                        couponsUserDel(
                                          data.code,
                                          data.couponId._id
                                        )
                                      "
                                    >
                                      SİL
                                    </v-btn>
                                  </td>
                                </tr>
                                <tr v-else>
                                  <td>Kullanıcının Kuponu Bulunmamaktadır</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import TelAllCountries from "@/assets/js/all-countries";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"; //h ttps://chronotruck.github.io/vue-ctk-date-time-picker/
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"; //https://chronotruck.github.io/vue-ctk-date-time-picker/
import moment from "moment";
import vSelect from "vue-select";
import AgentNote from "@/components/AgentNote";
import { IMaskComponent } from "vue-imask";
import { cibLgtm } from "@coreui/icons";
import { RoleProvider } from "../../provider";
var _ = require("lodash");
Vue.use(VueSweetalert2);
const { VUE_APP_API_URL } = process.env;
// ? Css.
import "vue-select/dist/vue-select.css";
var filterParams = {
  buttons: ["reset"],
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    cellValue = moment(cellValue).startOf("day").toDate();
    cellValue = cellValue.getTime();
    filterLocalDateAtMidnight = filterLocalDateAtMidnight.getTime();
    if (cellValue == null) return 0;

    if (cellValue < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellValue > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2025,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};
export default {
  name: "Users",
  components: {
    AgGridVue,
    vSelect,
    AgentNote,
    VueCtkDateTimePicker,
    "imask-input": IMaskComponent,
    RoleProvider,
    VueTelInput,
  },

  data() {
    return {
      defaultExcelExportParams: null,
      rules: {
        required: (value) => !!value || "Zorunlu alan.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Geçersiz e-mail";
        },
        phone: (value) => {
          const pattern =
            /^[\+]?[(]?[0-9]{2,4}[)]?[-\s]?([0-9]{3}[-\s]?){2}[0-9]{2}([-\s]?[0-9]{2})?$/;
          return pattern.test(value) || "Geçersiz telefon.";
        },
        identity: (value) => {
          let pattern;
          if (this.userModalAddUpdate.form.certificateType === "tc") {
            pattern = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
            return pattern.test(value) || "Geçersiz T.C. Kimlik Numarası";
          } else {
            pattern = /^.{5,}$/;
            return pattern.test(value) || "Geçersiz Pasaport Numarası";
          }
        },
      },

      couponsName: {
        name: "Kupon Seçin",
        items: [],
      },
      tab: null,
      couponsNumber: "",
      whereDidYouHearJSON: [
        "Instagram",
        "Twitter",
        "Facebook",
        "Google/Arama Motoru",
        "Youtube",
        "Broşür-Katalog-Insert",
        "Fuar/Etkinlik/Stand Faaliyetinden",
        "Radyo",
        "TV Reklamı",
        "Euro Kanallar / Bant Yayınları",
        "Referans/Tavsiye",
        "Açıkhava Reklamı",
        "Bilinmiyor/Hatırlamıyor",
        "RentiCar Çalışanı",
        "İş Birliği",
      ],
      couponsJSON: [],
      couponsData: [],
      selectedDeleteOption: null,
      deleteAccountDialog: false,
      deleteQuestions: [],
      isPhoneFormat: false,
      phoneUpdate: {
        modal: false,
        newPhone: null,
        newPhoneInput: null,
      },
      couponModalShow: false,
      invoiceTypes: [
        {
          value: "individual",
          label: "Bireysel",
        },
        {
          value: "individualCompany",
          label: "Şahış Şirketi",
        },
        {
          value: "company",
          label: "Kurumsal",
        },
        {
          value: "abroad",
          label: "Yabancı",
        },
      ],
      searchFilterInput: "",
      agentNotesTotal: 0,
      options: [
        {
          value: 0,
          text: "Angular",
        },
        {
          value: 1,
          text: "Bootstrap",
        },
        {
          value: 2,
          text: "React.js",
        },
        {
          value: 3,
          text: "Vue.js",
        },
        {
          label: "backend",
          options: [
            {
              value: 4,
              text: "Django",
            },
            {
              value: 5,
              text: "Laravel",
            },
            {
              value: 6,
              text: "Node.js",
            },
          ],
        },
      ],
      driverIdentityValue: [
        {
          value: 0,
          tr: "T.C. Kimlik Numarası",
          en: "T.C. Identity Number",
        },
        {
          value: 1,
          tr: "Pasaport Numarası",
          en: "Passport Number",
        },
      ],
      invoiceTypeValue: [
        {
          value: "individual",
          tr: "Bireysel",
          en: "Individual",
        },
        {
          value: "individualCompany",
          tr: "Bireysel Şirket",
          en: "IndividualCompany",
        },
        {
          value: "company",
          tr: "Şirket",
          en: "Company",
        },
        {
          value: "abroad",
          tr: "Yabancı Şirket",
          en: "Abroad",
        },
      ],
      userEmailToken: "",
      userPhoneToken: "",
      // GENEL
      func: Func,
      settings: null,

      // AG GRID

      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak bileşenler
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      search: "",
      resDataHeaders: [
        {
          text: "Rezervasyon Numarası",
          align: "start",
          sortable: false,
          value: "reservationCode",
        },
        { text: "Durumu", value: "status" },
        { text: "Tarihi", value: "reservationDateTime" },
        { text: "Alış Yeri", value: "searchPrice.office.name" },
        { text: "Teslim Yeri", value: "searchPrice.returnOffice.name" },
        { text: "Firma", value: "searchPrice.vendor.name" },
        { text: "Tutarı", value: "prices.totalPrice.TRY" },
      ],
      userInfoUpdate: false,
      userInvoiceUpdate: false,
      userCouponAdd: false,
      userPermissionUpdate: false,
      searchBidResCollapsed: true,
      searchBidPaymentCollapsed: false,
      searchBidSurveyCollapsed: false,
      reservationsDetail: [],

      // USER EKLEME GÜNCELLEME MODAL
      userModalAddUpdate: {
        modal: false,
        title: "",
        process: "",
        btnDisable: false,
        fields: {
          name: {
            options: {
              min: 2,
              max: 75,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          lastname: {
            options: {
              min: 2,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          email: {
            options: {
              min: 5,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          phone: {
            options: {
              allCountries: TelAllCountries, //   Type: Array =>          Default: An array of all countries, see allCountries.js => All countries that are used in libphonenumber-js, can be overridden by this prop
              autoFormat: true, //                Type: Boolean =>        Default: true =>        Auto update the input to the formatted phone number when it's valid
              customValidate: false, //           Type: Boolean, RegExp => Default: false =>      Custom validation RegExp for input
              defaultCountry: "tr", //            Type: String, Number => Default: '' =>          Default country (by iso2 or dialCode), will override the country fetched from IP address of user
              disabled: false, //                 Type: Boolean =>        Default: false =>       Disable vue-tel-input, including the input & flag dropdown
              autoDefaultCountry: true, //        Type: Boolean =>        Default: true =>        To fetch default country based on IP address of user
              ignoredCountries: [], //            Type: Array =>          Default: [] =>          List of countries will NOT be shown on the dropdown
              errorMessage: "", //                Type: String =>         Default: ''
              mode: "national", //           Type: String =>         Default: 'auto' =>      Allowed values: 'auto' (Default set by phone), 'international' (Format number with the dial code i.e. + 61), 'national' (Format number without dial code i.e. 0321232)
              onlyCountries: [], //               Type: Array =>          Default: [] =>          List of countries will be shown on the dropdown
              preferredCountries: [], //          Type: Array =>          Default: [] =>          Preferred countries list, will be on top of the dropdown
              styleClasses: "", //                Type: String, Array, Object => Default: '' =>   Custom classes for the wrapper
              validCharactersOnly: true, //       Type: Boolean =>        Default: false =>       Only allow valid characters in a phone number (will also verify in mounted, so phone number with invalid characters will be shown as an empty string)

              label: "Cep Telefonu",
              labelBold: true,
              min: 17,
              max: 17,
              shake: false,
            },
            inputOptions: {
              autocomplete: "on", //              Type: String =>         Default: 'on' =>        Native input autocomplete attribute
              autofocus: false, //                Type: Boolean =>        Default: false =>       Native input autofocus attribute
              "aria-describedby": "", //          Type: String =>         Default: '' =>          Native input aria-describedby attribute
              id: "", //                          Type: String =>         Default: '' =>          Native input id attribute
              maxlength: 17, //                   Type: Number =>         Default: 25 =>          Native input maxlength attribute
              name: "telephone", //               Type: String =>         Default: 'telephone' => Native input name attribute
              placeholder: "", // Type: String => Default: 'Enter a phone number' => Placeholder for the input
              readonly: false, //                 Type: Boolean =>        Default: false =>       Native input readonly attribute
              required: true, //                  Type: Boolean =>        Default: false =>       Native input required attribute
              tabindex: 0, //                     Type: Number =>         Default: 0 =>           Native input tabindex attribute
              type: "tel", //                     Type: String =>         Default: 'tel' =>       Native input type attribute
              styleClasses: "", //                Type: String, Array, Object => Default: '' =>   Custom classes for the input
            },
            dropdownOptions: {
              disabled: false, //                 Type: Boolean =>        Default: false =>       Disable dropdown
              showDialCodeInList: true, //        Type: Boolean =>        Default: true =>        Show dial code in the dropdown list
              showDialCodeInSelection: false, //  Type: Boolean =>        Default: false =>       Show dial code in the dropdown selection
              showFlags: true, //                 Type: Boolean =>        Default: true =>        Show flags in the dropdown selection and list
              tabindex: 0, //                     Type: Number =>         Default: 0 =>           Native dropdown tabindex attribute
            },
            valid: {
              value: null,
              text: "",
            },
          },
          birthday: {
            options: {
              minDate: null,
              maxDate: null,
            },
          },
          defaultIdType: {
            options: [
              {
                value: 0,
                label: "TC Kimlik",
              },
              {
                value: 1,
                label: "Pasaport",
              },
            ],
          },
          tcId: {
            options: {
              min: 11,
              max: 11,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passportId: {
            options: {
              min: 7,
              max: 9,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          status: {
            options: [
              {
                value: "Active",
                label: "Aktif",
              },
              {
                value: "InActive",
                label: "Pasif",
              },
              {
                value: "Pending",
                label: "Bekliyor",
              },
              {
                value: "Banned",
                label: "Engellendi",
              },
            ],
          },
          lang: {
            options: [
              {
                value: "tr",
                label: "Türkçe",
              },
              {
                value: "en",
                label: "İngilizce",
              },
            ],
          },
          permissions: {
            options: [
              {
                value: "sms",
                label: "SMS",
              },
              {
                value: "phone",
                label: "Telefon",
              },
              {
                value: "email",
                label: "E-Posta",
              },
            ],
          },
        },
        form: {
          _id: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
          phoneInput: "",
          phone: "",
          birthday: "",
          defaultIdType: 0,
          tcId: "",
          passportId: "",
          permissions: ["sms", "phone", "email"],
          checkboxTI: 1,
          birthday: null,

          invoices: {
            invoiceType: "individual",
            individual: {
              province: "",
              postCode: "",
              address: "",
            },
            individualCompany: {
              firstname: "",
              lastname: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            company: {
              title: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            abroad: {
              title: "",
              firstname: "",
              lastname: "",
              passportNumber: "",
              taxNumber: "",
              country: "",
              province: "",
              postCode: "",
              address: "",
            },
          },
          whereDidYouHear: "",
        },
      },

      // USER ŞİFRE GÜNCELLEME MODAL
      userModalPasswordUpdate: {
        modal: false,
        title: "",
        btnDisable: false,
        fields: {
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
        },
        form: {
          _id: "",
          password: "",
          passwordAgain: "",
        },
      },
      tabItem: [
        {
          title: "Notlar",
          data: [],
        },
        {
          title: "Rezervasyonlar",
          data: [],
        },
        {
          title: "Kuponlar",
          data: [],
        },
      ],
      couponColumnDefs: [
        {
          headerName: "Actions",
          cellRenderer: "buttonRenderer",
        },
      ],
      columnDefs: [
        {
          field: "status",
          headerName: this.$t("status"),
          enableRowGroup: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRenderer: (params) => {
            var result = "";
            var reservationStatus = params.value;
            if (reservationStatus == "approved") {
              result = this.$t("approved");
            } else if (reservationStatus == "pending") {
              result = this.$t("pending_t");
            } else if (reservationStatus == "active") {
              result = this.$t("active");
            } else if (reservationStatus == "completed") {
              result = this.$t("completed");
            } else if (reservationStatus == "cancelled") {
              result = this.$t("cancelled");
            } else if (reservationStatus == "billed") {
              result = this.$t("billed");
            } else if (reservationStatus == "notbilled") {
              result = this.$t("notbilled");
            } else if (reservationStatus == "canceledbill") {
              result = this.$t("canceledbill");
            }
            // else if (reservationStatus == "earlyReturnRequest") {
            //   result = this.$t("earlyReturnRequest");
            // }
            // else if (
            //   reservationStatus == "earlyReturnFinancialConfirmation"
            // ) {
            //   result = this.$t("earlyReturnRequestWaitConfirm");
            // } else if (reservationStatus == "confirmedEarlyReturn") {
            //   result = this.$t("confirmEarlReturn");
            // }
            else if (reservationStatus == "paymentError") {
              result = this.$t("paymentError");
            } else if (reservationStatus == "noShowCancelled") {
              result = this.$t("noShowCancelled");
            } else {
              result = this.$t("all");
            }

            return result;
          },
          width: 120,
          hide:
            typeof this.$router.history.current.params.reservationStatus !==
            "undefined"
              ? true
              : false,
        },

        {
          field: "statusEarlyReturn",
          valueGetter: (params) => {
            return (
              this.$store.state.ceviri[
                "earlyReturn_" + params?.data?.statusEarlyReturn
              ] || "-"
            );
          },
          headerName: "Erken İade",
          width: 120,
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Medium",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_medium || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Campaign",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_campaign || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Term",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_term || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Content",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_content || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Referans",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.referrer || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "reservationType",
          headerName: this.$t("type"),
          cellRenderer: (params) => {
            return this.$t(params.value);
          },
          width: 100,
        },

        {
          field: "searchPrice.vendor.name",
          headerName: this.$t("vendor"),
          enableRowGroup: true,
          width: 100,
        },
        {
          field: "guest",
          enableRowGroup: true,
          headerName: this.$t("user_type"),
          hide: true,
          width: 120,
          cellRenderer: (params) => {
            switch (params.value) {
              case true:
                return this.$t("quest_member");
              case false:
                return this.$t("registered_member");
              default:
                return this.$t("unknown");
            }
          },
        },

        {
          field: "driverInfo.name",
          headerName: this.$t("name"),
          width: 110,
        },
        {
          field: "driverInfo.lastname",
          headerName: this.$t("lastname"),
          width: 110,
        },

        {
          field: "driverInfo.identity",
          headerName: "TCKN / Pasaport",
          cellRenderer: (params) => {
            const value = params?.value;
            if (value.certificateType === "tc" && value.value)
              return `<b>TC:</b> ${value.value}`;
            else if (value.certificateType === "passport" && value.value)
              return `<b>P:</b> ${value.value}`;
            else return "Girilmemiş";
          },
        },

        {
          field: "reservationCode",
          headerName: this.$t("reservation_number"),
          width: 130,
        },
        {
          field: "reservationCode",
          valueGetter: (params) => {
            return `https://www.renticar.com/gosterge-paneli/rezervasyonlarim/detay/${params?.data?.reservationCode}/${params?.data?.driverInfo.email}#comment`;
          },
          hide: true,
          headerName: this.$t("survey_link"),
          width: 130,
        },
        {
          field: "vendorReservationCode",
          headerName: this.$t("vendor_reservation_number"),
          width: 140,
        },
        {
          field: "rentalDayRange",
          headerName: this.$t("rental_day_range"),
          width: 90,
        },
        {
          field: "driverInfo.flightNo",
          headerName: this.$t("flight_number"),
          width: 110,
          hide: true,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("DD.MM.YYYY");
          },
          valueGetter: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("YYYY-MM-DD");
          },
          headerName: this.$t("reservation_date"),
          width: 100,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("HH:mm");
          },
          valueGetter: (params) => {
            return (
              moment(params.data.reservationDateTime).local().format("HH:mm") ||
              ""
            );
          },
          headerName: this.$t("reservation_hour"),
          width: 90,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("DD.MM.YYYY");
              }
            }
          },
          headerName: this.$t("cancel_date"),
          width: 140,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("HH:mm");
              }
            }
          },
          headerName: this.$t("cancel_time"),
          width: 140,
        },
        {
          field: "refund.place",
          headerName: this.$t("cancel_author"),
          width: 100,
        },
        {
          enableRowGroup: true,
          field: "refund.message",
          valueGetter: (params) => {
            return params?.data?.refund?.message || "";
          },
          headerName: this.$t("reason_for_cancellation"),
          width: 150,
        },
        {
          enableRowGroup: true,
          field: "status",
          headerName: this.$t("error_reason"),
          width: 120,
          valueGetter: (data) => {
            const transactions = data?.data?.bankTransactions || [];
            const lastTransaction = transactions[transactions.length - 1];
            return lastTransaction?.message || "";
          },
        },

        {
          field: "prices.dailyRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.dailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("daily_rental_fee"),
          width: 110,
        },
        {
          field: "prices.retailDailyRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params.data?.prices?.retailDailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailDailyRentalPrice"),
          width: 110,
        },
        {
          field: "currency.forexSelling",
          cellRenderer: (params) => {
            let forexSelling =
              params.data.currency.forexSelling == 1
                ? ""
                : params.data.currency.forexSelling.toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  });
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "İşlem Kuru",
          width: 100,
        },
        {
          field: "searchPrice.totalDays",
          cellRenderer: (params) => {
            return params.value;
          },
          type: "rightAligned",
          headerName: this.$t("rental_days"),
          width: 70,
        },
        {
          field: "prices.totalRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.totalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_payment_price"),
          width: 110,
        },
        {
          field: "prices.retailTotalRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params?.data?.prices?.retailTotalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailTotalRentalPrice"),
          width: 110,
        },
        {
          field: "selectedExtras",
          valueGetter: (params) => {
            return (
              params.data?.prices?.totalExtraPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_extras"),
          width: 110,
        },

        {
          hide: true,
          field: "bankTransactions",
          headerName: this.$t("installment_count"),
          width: 110,
          cellRenderer: (params) => {
            return (
              params.value.find((item) => item.type == "paymentSuccess")?.data
                ?.InstallmentCount || ""
            );
          },
        },
        {
          field: "posModule",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Pos",
          width: 140,
          hide: true,
        },
        {
          field: "metropolCard",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Metropol Kart",
          width: 140,
          hide: true,
        },
        {
          field: "orderId",
          headerName: this.$t("order_number"),
          width: 300,
          hide: true,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "green" },
          hide: true,
          valueGetter: (params) => {
            if (params?.data.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                return Number(Func.toMoneyFormat(sonuc));
              }
            } else {
              return 0;
            }
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            });
          },
          headerName: this.$t("amount_paid"),
          width: 140,
        },
        {
          field: "payments",
          hide: true,
          type: "rightAligned",
          valueGetter: (params) => {
            if (params?.data?.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                var result = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                if (params.data?.prices?.totalPrice?.TRY) {
                  result = sonuc - params.data?.prices?.totalPrice.TRY;
                  return Number(Func.toMoneyFormat(sonuc)).toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "TRY",
                    }
                  );
                }
              }
            }
            return 0;
          },
          headerName: this.$t("interest"),
          width: 140,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "red" },
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorTotalPrice?.TRY !==
              "undefined"
            ) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "refund"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc = sonuc + sayi.totalAmount;
                });
                return sonuc.toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                });
              }
            }
            return 0;
          },
          headerName: this.$t("returned"),
          hide: true,
          width: 140,
        },
        {
          field: "carDeliveryReturn.delivery.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("purchase_date"),
          width: 140,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },
        {
          field: "carDeliveryReturn.return.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("returned_date"),
          width: 140,
          filter: "agDateColumnFilter",
          filterParams: filterParams,
        },

        {
          field: "ERP.invoiceTransferStatus",
          cellRenderer: (params) => {
            return params.value == "ok"
              ? this.$t("invoiced")
              : params.value == "pending"
              ? this.$t("pending")
              : params.value == "error"
              ? this.$t("error_t")
              : "";
          },
          headerName: this.$t("invoice_status"),
          width: 140,
          hide: true,
        },
        {
          field: "ERP.invoiceDate",
          cellRenderer: (params) => {
            return params.value
              ? moment(params.value).format("DD.MM.YYYY")
              : "";
          },
          headerName: this.$t("invoice_date"),
          width: 140,
          hide: true,
        },
        {
          field: "ERP.invoiceNumber",
          headerName: this.$t("invoice_number"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData",
          valueGetter: (params) => {
            return params.data.searchPrice.campaignData != null
              ? this.$t("yes")
              : this.$t("not");
          },
          headerName: this.$t("with_a_campaign"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData.info.name.tr",
          headerName: this.$t("campaign_name"),
          valueGetter: (params) => {
            const data = params.data.searchPrice;
            if (data?.campaignData?.info?.name?.tr) {
              return data.campaignData.info.name.tr;
            } else if (data?.campaignData?.info?.name) {
              return data.campaignData.info.name;
            } else {
              return "";
            }
          },
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountRate",
          headerName: this.$t("campaign_discount_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountAmount",
          headerName: this.$t("campaign_discount_price"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.vendorCommission",
          headerName: this.$t("campaign_vendor_commision_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("delivery_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.return.name",
          headerName: this.$t("return_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("differen_returns"),
          width: 110,
          valueGetter: (params) => {
            return params.data.carDeliveryReturn.return.name !=
              params.data.carDeliveryReturn.delivery.name
              ? this.$t("yes")
              : this.$t("not");
          },
          hide: true,
        },
        {
          field: "prices.dropPrice",
          headerName: this.$t("different_return_amount"),
          type: "rightAligned",
          width: 140,
          valueGetter: (params) => {
            return (
              params.data?.prices?.dropPrice[this.$store.state.currency] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params?.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
        },
        {
          field: "searchPrice.car.model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "searchPrice.car.brand",
          headerName: this.$t("brand"),
          width: 100,
        },
        {
          field: "searchPrice.car.class",
          headerName: "Araç Sınıfı",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },
          width: 100,
        },
        {
          field: "userType",
          valueGetter: (params) => {
            if (params.data?.modifications[0].departmanRole) {
              return (
                this.$store.state.ceviri[
                  params.data.modifications[0].userType
                ] +
                "-" +
                this.$store.state.ceviri[
                  params.data.modifications[0].departmanRole
                ]
              );
            } else {
              return this.$store.state.ceviri[
                params.data.modifications[0].userType
              ];
            }
          },
          headerName: this.$t("operating_user_type"),
          width: 160,
          hide: true,
        },

        {
          field: "driverInfo.email",
          headerName: this.$t("driver_email"),
          width: 200,
        },
        {
          field: "driverInfo.phone",
          headerName: this.$t("driver_phone"),
          width: 130,
          hide: true,
        },
        {
          field: "spendingObjection",
          headerName: this.$t("spending_objection"),
          width: 130,
          cellRenderer: ({ data: { spendingObjection } }) =>
            spendingObjection ? this.$t("there_is") : this.$t("none"),
          valueGetter: ({ data: { spendingObjection } }) =>
            spendingObjection ? this.$t("there_is") : this.$t("none"),
          hide: true,
        },
        {
          field: "reservationBy",
          headerName: this.$t("trader"),
          width: 150,
          hide: true,
          valueGetter: (params) => {
            return params.data.modifications.find(
              (item) => item.change == "created"
            ).fullname;
          },
          cellRenderer: (params) => {
            return params.data.modifications.find(
              (item) => item.change == "created"
            ).fullname;
          },
        },
        {
          field: "paymentType",
          valueGetter: (params) => {
            let type = params?.data?.paymentType
              ? this.$store.state.ceviri[params.data.paymentType]
              : "";
            return type;
          },
          headerName: this.$t("payment_type"),
          width: 110,
        },
        {
          field: "userID.permissions",
          headerName: "Arama İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.userID?.permissions;
            return phonePermission && phonePermission.includes("phone")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "userID.permissions",
          headerName: "SMS İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.userID?.permissions;
            return phonePermission && phonePermission.includes("sms")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "userID.permissions",
          headerName: "Email İzni",
          valueGetter: (params) => {
            var phonePermission = params?.data?.userID?.permissions;
            return phonePermission && phonePermission.includes("email")
              ? "Onay"
              : "Red";
          },
          cellStyle: (params) => {
            return (params =
              params?.value == "Onay"
                ? { color: "#2eb85c", fontWeight: "bold" }
                : { color: "#e55353", fontWeight: "light" });
          },
          hide: true,
          width: 70,
        },
        {
          field: "isComment.average",
          headerName: this.$t("comment_average"),
          width: 150,
        },
        {
          field: "pollCounter",
          headerName: this.$t("is_comment_sent"),
          width: 170,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: this.$t("columns"),
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false,
            },
          },
          {
            id: "filters",
            labelDefault: this.$t("filters"),
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple",
    };
  },
  async created() {
    await this.getSettings();
    this.getUsers();
    this.getcouponsJSON();
    // this.defaultExcelExportParams = {
    //   processCellCallback: (params) => {
    //     if (params.column.colDef.headerName === "TCKN / Pasaport") {
    //       const data = params.data.user;
    //       if (params.value === 0 && data?.tcId) {
    //         return `TC: ${data.tcId}`;
    //       } else if (params.value !== 0 && data?.passportId) {
    //         return `P: ${data?.passportId}`;
    //       } else {
    //         return "Girilmemiş";
    //       }
    //     } else if (params.column.colDef.headerName === "Arama İzni") {
    //       return params.value.includes("phone") ? "ONAY" : "RED";
    //     } else if (params.column.colDef.headerName === "SMS İzni") {
    //       return params.value.includes("sms") ? "ONAY" : "RED";
    //     } else if (params.column.colDef.headerName === "Email İzni") {
    //       return params.value.includes("email") ? "ONAY" : "RED";
    //     } else if (params.column.colDef.headerName === "Kayıt Tarihi") {
    //       return moment(params.value).format("DD.MM.YYYY - HH:mm");
    //     } else if (params.column.colDef.headerName === "Doğum Tarihi") {
    //       return moment(params.value).format("DD.MM.YYYY - HH:mm");
    //     } else if (params.column.colDef.headerName === "Üye Yapan") {
    //       return params.value ? "Agent" : "User";
    //     } else if (params.column.colDef.headerName === "Uyruk") {
    //       if (params.value === 0) return "Türk Vatandaşı";
    //       else return "Yabancı Uyruk";
    //     } else {
    //       return params.value;
    //     }
    //   },
    // };
    this.getDeleteQuestions();
  },
  watch: {
    "userModalAddUpdate.form.permissions": function (value) {
      if (value.length == 0) {
        this.userModalAddUpdate.form.consent = false;
      } else {
        this.userModalAddUpdate.form.consent = true;
      }
    },
    "userModalAddUpdate.form.defaultIdType": function (value) {
      if (value) {
        this.userModalAddUpdate.fields.tcId.valid.value = null;
        this.userModalAddUpdate.fields.tcId.valid.text = "";
        this.userModalAddUpdate.fields.passportId.valid.value = null;
        this.userModalAddUpdate.fields.passportId.valid.text = "";
      }
    },
  },
  methods: {
    onColumnMoved(params) {
      var columnState = JSON.stringify(params.columnApi.getColumnState());
      localStorage.setItem("gridReservations", columnState);
    },

    getClasses() {
      //
      let classes = {
        "ag-theme-balham": true,
      };
      if (!this.searchBidResCollapsed) {
        classes["fp-table"] = true;
      } else {
        classes["fp-table-open"] = true;
      }
      return classes;
    },
    onRowDoubleClicked(params) {
      let routeData = this.$router.resolve({
        name: "Rezervasyon Detayı",
        params: {
          reservationCode: params.node.data.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },
    redirectUserReservatins() {
      window.location.href =
        process.env.VUE_APP_ADMIN_URL + "reservation/index/?" + "userID=" + id;
    },
    async getDeleteQuestions() {
      let result = await axios.get(
        process.env.VUE_APP_API_URL +
          "admin/singular?slug=USER_DELETE_QUESTIONS",
        {
          withCredentials: true,
        }
      );
      let items = result.data.data.items;
      items.filter((item, index) => {
        if (item.status === "active") {
          this.deleteQuestions.push({
            text: this.$i18n.locale === "tr" ? item.name : item.name_en,
            value: {
              tr: item.name,
              en: item.name_en,
            },
          });
        }
      });
    },
    deleteAccount() {
      this.deleteAccountDialog = true;
    },
    async couponsUserAdd() {
      try {
        await axios.put(`${VUE_APP_API_URL}admin/coupon-set-user`, {
          useageCreate: this.couponsNumber,
          userId: this.userModalAddUpdate.form._id,
          couponId: this.couponsName._id,
        });
        this.couponsUserGet(this.userModalAddUpdate.form._id);
        this.couponsName = {
          name: "Kupon Seçin",
          items: [],
        };
        this.couponsNumber = "";
        this.userCouponAdd = false;
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: error.response.data.message,
          showCloseButton: true,
        });
      }
    },
    async couponsUserDel(code, titleCouponId) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu kuponu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let params = {
              userId: this.userModalAddUpdate.form._id,
              titleCouponId,
              code,
            };
            try {
              await axios.delete(`${VUE_APP_API_URL}admin/coupon-delete-user`, {
                data: params,
              });
              this.couponsUserGet(this.userModalAddUpdate.form._id);
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    async couponsUserGet(id = this.userModalAddUpdate.form._id) {
      try {
        let { data } = await axios.get(
          `${VUE_APP_API_URL}admin/coupon-get-user?userId=${id}`
        );
        // this.couponsData = data;
        this.tabItem[2].data = data;
      } catch (error) {}
    },
    async getcouponsJSON() {
      try {
        let { data } = await axios.get(
          `${VUE_APP_API_URL}admin/coupons?all=true`
        );
        this.couponsJSON = data;
      } catch (error) {}
    },
    onInput(formattedNumber, { number, valid, country }) {
      if (valid === true) {
        this.userModalAddUpdate.form.phone = number;
      }
    },
    onInputNew(formattedNumber, { number, valid, country }) {
      if (valid === true) {
        this.isPhoneFormat = true;
        this.phoneUpdate.newPhone = number;
      } else {
        this.isPhoneFormat = false;
      }
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    async userEmailReset(id) {
      Vue.swal
        .fire({
          icon: "question",
          text: "E-Posta adresini güncellemek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            Vue.swal
              .fire({
                icon: "info",
                text: "Yeni E-Posta Adresini yazınız",
                input: "email",
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText: "Gönder",
                showLoaderOnConfirm: true,
                preConfirm: async (email) => {
                  var params = {
                    id,
                    email,
                  };
                  try {
                    // var result = await axios.post(
                    //   process.env.VUE_APP_API_URL +
                    //     "admin/users/email/verify-control-create",
                    //   params,
                    //   {
                    //     withCredentials: true,
                    //   }
                    // );
                    // this.userEmailToken = result.data.token;

                    await this.userEmailSend(id, email);
                  } catch (error) {
                    Vue.swal.fire({
                      icon: "error",
                      showCloseButton: true,
                      text: error.response.data.message,
                    });
                  }
                },
                allowOutsideClick: () => !Vue.swal.isLoading(),
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.successSwal(
                    "E-Posta adresi başarılı bir şekilde güncellendi"
                  );
                }
              });
          }
        })
        .catch((error) => {
          Vue.swal
            .fire({
              icon: "error",
              text: error.response.data.message || "Bir sorun oluştu",
              showCancelButton: false,
              confirmButtonText: "Yeniden Dene",
              showCloseButton: true,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.userEmailReset(id);
              }
            });
        });
    },

    async userEmailSend(id, email) {
      var params = {
        email,
        oldEmail: this.userModalAddUpdate.form.email,
        userID: id,
      };
      try {
        var result = await axios.post(
          process.env.VUE_APP_API_URL + "admin/users/email/update",
          params,
          {
            withCredentials: true,
          }
        );
        this.successSwal("E-Posta adresi başarılı bir şekilde güncellendi");
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message,
        });
      }
    },
    updateInvoiceType(selectedItem) {
      this.userModalAddUpdate.form.invoiceType = selectedItem.value;
    },

    /**
       * Kullanıcı email değiştirme kod onayı
      userEmailSend(id, email) {
        Vue.swal
          .fire({
            icon: "warning",
            text: "E-posta adresine gönderilen kodu talep ediniz",
            input: "text",
            showCancelButton: false,
            confirmButtonText: "Devam Et",
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async (code) => {
              var params = {
                code,
                token: this.userEmailToken,
              };
              try {
                var result = await axios.post(
                  process.env.VUE_APP_API_URL +
                    "admin/users/email/verify-control-check",
                  params,
                  {
                    withCredentials: true,
                  }
                );
                this.userEmailToken = result.data.token;
              } catch (error) {
                Vue.swal
                  .fire({
                    icon: "error",
                    text: error.response.data.message,
                    showCancelButton: false,
                    confirmButtonText: "Yeniden Dene",
                    showCloseButton: true,
                  })
                  .then(async (result) => {
                    if (result.isConfirmed) {
                      this.userEmailSend(id, email);
                    }
                  });
              }
            },
            allowOutsideClick: () => !Vue.swal.isLoading(),
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              var params = {
                email,
                oldEmail: this.userModalAddUpdate.form.email,
                token: this.userEmailToken,
              };
              try {
                var result = await axios.post(
                  process.env.VUE_APP_API_URL + "admin/users/email/update",
                  params,
                  {
                    withCredentials: true,
                  }
                );
                this.userEmailToken = result.data.token;
                this.successSwal(
                  "E-Posta adresi başarılı bir şekilde güncellendi"
                );
              } catch (error) {
                Vue.swal.fire({
                  icon: "error",
                  showCloseButton: true,
                  text: error.response.data.message,
                });
              }
            }
          });
      },
       */

    async userPhoneReset(id) {
      this.phoneUpdate.modal = true;
      var params = {
        phone: this.phoneUpdate.newPhone,
        id,
      };
      try {
        // var result = await axios.post(
        //   process.env.VUE_APP_API_URL +
        //     "admin/users/phone/verify-control-create",
        //   params,
        //   {
        //     withCredentials: true,
        //   }
        // );
        // this.userPhoneToken = result.data.token;
        if (this.isPhoneFormat) {
          await this.userPhoneSend(id, this.phoneUpdate.newPhone);
        } else {
          return error;
        }
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response
            ? error.response.data.message
            : "Telefon Numarasını doğru formatta girin",
        });
      }
    },

    async userPhoneSend(id, phone) {
      var params = {
        phone,
        oldPhone: this.userModalAddUpdate.form.phone,
        userID: id,
      };
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/users/phone/update",
          params,
          {
            withCredentials: true,
          }
        );
        this.successSwal("Cep Telefonu başarılı bir şekilde güncellendi");
      } catch (error) {
        Vue.swal
          .fire({
            icon: "error",
            text: error.response.data.message,
            showCancelButton: false,
            confirmButtonText: "Yeniden Dene",
            showCloseButton: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.userPhoneSend();
            }
          });
      }
    },

    /**
       *  Kullanıcı Telefon değiştirme kod onayı
      userPhoneSend() {
        Vue.swal
          .fire({
            icon: "warning",
            text: "Kullanıcının Cep telefonuna gönderilen SMS kodunu talep ediniz",
            input: "text",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonText: "Gönder",
            showLoaderOnConfirm: true,
            preConfirm: async (code) => {
              var params = {
                code,
                oldPhone: this.userModalAddUpdate.form.phone,
                token: this.userPhoneToken,
              };
              try {
                await axios.post(
                  process.env.VUE_APP_API_URL + "admin/users/phone/update",
                  params,
                  {
                    withCredentials: true,
                  }
                );
              } catch (error) {
                Vue.swal
                  .fire({
                    icon: "error",
                    text: error.response.data.message,
                    showCancelButton: false,
                    confirmButtonText: "Yeniden Dene",
                    showCloseButton: true,
                  })
                  .then(async (result) => {
                    if (result.isConfirmed) {
                      this.userPhoneSend();
                    }
                  });
              }
            },
            allowOutsideClick: () => !Vue.swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.successSwal("Cep Telefonu başarılı bir şekilde güncellendi");
            }
          });
      },
       */

    userPasswordReset(userID) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Yeni şifre göndermek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var params = {
              userID,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/users/send-email-lost-password",
                params,
                {
                  withCredentials: true,
                }
              );

              this.successSwal(
                "Yeni şifre oluşturulup kullanıcıya EMAİL olarak gönderildi",
                false
              );
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                title: "Hata",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    userChangeStatus(id, status) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu statüsünü " + status + " çekmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var params = {
                id,
                status,
                permissions: [],
              };
              await axios.put(
                process.env.VUE_APP_API_URL + "admin/users/status-update/",
                params,
                {
                  withCredentials: true,
                }
              );
              this.successSwal("Kullanıcının Statüsü değiştirildi");
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    userDelete(params) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu kullanıcıyı silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/users/" + params,
                {
                  withCredentials: true,
                }
              );
              this.successSwal("Kullanıcı silindi");
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    deleteAccountProcess(params) {
      try {
        axios.post(
          process.env.VUE_APP_API_URL + "admin/delete-account/",
          { params: params, deleteReason: this.selectedDeleteOption },
          {
            withCredentials: true,
          }
        );
        this.deleteAccountDialog = false;
        this.successSwal("Kullanıcı Üyelik İptal Talebi Oluşturuldu");
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message,
        });
      }
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      //params.api.sizeColumnsToFit();
    },

    openUserModalAddUpdateForm(operation, title) {
      this.resetUserModalAddUpdateForm();
      this.userModalAddUpdate.process = operation;
      this.userModalAddUpdate.title = title;
      this.userModalAddUpdate.modal = true;
    },

    resetUserModalAddUpdateForm() {
      this.userModalAddUpdate.form._id = "";
      this.userModalAddUpdate.form.name = "";
      this.userModalAddUpdate.form.lastname = "";
      this.userModalAddUpdate.form.email = "";
      this.userModalAddUpdate.form.phone = "";
      this.userModalAddUpdate.form.birthday = "";
      this.userModalAddUpdate.form.defaultIdType = 0;
      this.userModalAddUpdate.form.tcId = "";
      this.userModalAddUpdate.form.passportId = "";
      this.userModalAddUpdate.form.permissions = ["sms", "phone", "email"];
      this.userModalAddUpdate.form.checkboxTI = 1;

      this.userModalAddUpdate.form.invoices.invoiceType = "individual";

      this.userModalAddUpdate.form.invoices.individual.province = "";
      this.userModalAddUpdate.form.invoices.individual.postCode = "";
      this.userModalAddUpdate.form.invoices.individual.address = "";

      this.userModalAddUpdate.form.invoices.individualCompany.firstname = "";
      this.userModalAddUpdate.form.invoices.individualCompany.lastname = "";
      this.userModalAddUpdate.form.invoices.individualCompany.taxOffice = "";
      this.userModalAddUpdate.form.invoices.individualCompany.taxNumber = "";
      this.userModalAddUpdate.form.invoices.individualCompany.province = "";
      this.userModalAddUpdate.form.invoices.individualCompany.postCode = "";
      this.userModalAddUpdate.form.invoices.individualCompany.address = "";

      this.userModalAddUpdate.form.invoices.company.title = "";
      this.userModalAddUpdate.form.invoices.company.taxOffice = "";
      this.userModalAddUpdate.form.invoices.company.taxNumber = "";
      this.userModalAddUpdate.form.invoices.company.province = "";
      this.userModalAddUpdate.form.invoices.company.postCode = "";
      this.userModalAddUpdate.form.invoices.company.address = "";

      this.userModalAddUpdate.form.invoices.abroad.title = "";
      this.userModalAddUpdate.form.invoices.abroad.firstname = "";
      this.userModalAddUpdate.form.invoices.abroad.lastname = "";
      this.userModalAddUpdate.form.invoices.abroad.passportNumber = "";
      this.userModalAddUpdate.form.invoices.abroad.taxNumber = "";
      this.userModalAddUpdate.form.invoices.abroad.country = "";
      this.userModalAddUpdate.form.invoices.abroad.province = "";
      this.userModalAddUpdate.form.invoices.abroad.postCode = "";
      this.userModalAddUpdate.form.invoices.abroad.address = "";
    },

    resetUserModalPasswordUpdateForm() {
      this.userModalPasswordUpdate.fields.password.valid.value = null;
      this.userModalPasswordUpdate.fields.password.valid.text = "";
      this.userModalPasswordUpdate.fields.passwordAgain.valid.value = null;
      this.userModalPasswordUpdate.fields.passwordAgain.valid.text = "";

      this.userModalPasswordUpdate.form.password = "";
      this.userModalPasswordUpdate.form.passwordAgain = "";
    },

    updatePermissionsCheckBox(value) {
      if (
        this.userModalAddUpdate.form.permissions.some((item) => item === value)
      ) {
        let index = this.userModalAddUpdate.form.permissions.findIndex(
          (item) => item === value
        );

        if (index !== -1) {
          this.userModalAddUpdate.form.permissions =
            this.userModalAddUpdate.form.permissions.filter(function (ele) {
              return ele != value;
            });
        }
      } else this.userModalAddUpdate.form.permissions.push(value);
    },

    getUserDetail(params) {
      console.log("adfas", params);
      const birthday = new Date(params.data.user.birthday);
      var dd = String(birthday.getDate()).padStart(2, "0");
      var mm = String(birthday.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = birthday.getFullYear();

      this.tabItem[1].data = params.data.userRes;

      this.userModalAddUpdate.process = "put";
      this.userModalAddUpdate.title = "Üye Düzenle";

      this.userModalAddUpdate.form._id = params.data.user._id;
      this.userModalAddUpdate.form.whereDidYouHear =
        params.data.user.whereDidYouHear;
      this.userModalAddUpdate.form.name = params.data.user?.name;
      this.userModalAddUpdate.form.lastname = params.data.user.lastname;
      this.userModalAddUpdate.form.email = params.data.user.email;
      this.userModalAddUpdate.form.phone = params.data.user.phone;
      this.userModalAddUpdate.form.birthday = dd + "." + mm + "." + yyyy;
      this.userModalAddUpdate.form.defaultIdType =
        params.data.user.defaultIdType;
      this.userModalAddUpdate.form.tcId = params.data.user.tcId;
      this.userModalAddUpdate.form.passportId = params.data.user.passportId;

      if (params.data.user.defaultIdType === 0) {
        this.userModalAddUpdate.form.tcId = params.data.user.tcId;
        this.userModalAddUpdate.form.passportId = "";
      } else {
        this.userModalAddUpdate.form.tcId = "";
        this.userModalAddUpdate.form.passportId = params.data.user.passportId;
      }
      if (params.data.user.invoices) {
        this.userModalAddUpdate.form.invoices.invoiceType =
          params.data.user.invoices.invoiceType;

        this.userModalAddUpdate.form.invoices.individual.province =
          params.data.user.invoices.individual.province;
        this.userModalAddUpdate.form.invoices.individual.postCode =
          params.data.user.invoices.individual.postCode;
        this.userModalAddUpdate.form.invoices.individual.address =
          params.data.user.invoices.individual.address;

        this.userModalAddUpdate.form.invoices.individualCompany.firstname =
          params.data.user.invoices.individualCompany.firstname;
        this.userModalAddUpdate.form.invoices.individualCompany.lastname =
          params.data.user.invoices.individualCompany.lastname;
        this.userModalAddUpdate.form.invoices.individualCompany.taxOffice =
          params.data.user.invoices.individualCompany.taxOffice;
        this.userModalAddUpdate.form.invoices.individualCompany.taxNumber =
          params.data.user.invoices.individualCompany.taxNumber;
        this.userModalAddUpdate.form.invoices.individualCompany.province =
          params.data.user.invoices.individualCompany.province;
        this.userModalAddUpdate.form.invoices.individualCompany.postCode =
          params.data.user.invoices.individualCompany.postCode;
        this.userModalAddUpdate.form.invoices.individualCompany.address =
          params.data.user.invoices.individualCompany.address;

        this.userModalAddUpdate.form.invoices.company.title =
          params.data.user.invoices.company.title;
        this.userModalAddUpdate.form.invoices.company.taxOffice =
          params.data.user.invoices.company.taxOffice;
        this.userModalAddUpdate.form.invoices.company.taxNumber =
          params.data.user.invoices.company.taxNumber;
        this.userModalAddUpdate.form.invoices.company.province =
          params.data.user.invoices.company.province;
        this.userModalAddUpdate.form.invoices.company.postCode =
          params.data.user.invoices.company.postCode;
        this.userModalAddUpdate.form.invoices.company.address =
          params.data.user.invoices.company.address;

        this.userModalAddUpdate.form.invoices.abroad.title =
          params.data.user.invoices.abroad.title;
        this.userModalAddUpdate.form.invoices.abroad.firstname =
          params.data.user.invoices.abroad.firstname;
        this.userModalAddUpdate.form.invoices.abroad.lastname =
          params.data.user.invoices.abroad.lastname;
        this.userModalAddUpdate.form.invoices.abroad.passportNumber =
          params.data.user.invoices.abroad.passportNumber;
        this.userModalAddUpdate.form.invoices.abroad.taxNumber =
          params.data.user.invoices.abroad.taxNumber;
        this.userModalAddUpdate.form.invoices.abroad.country =
          params.data.user.invoices.abroad.country;
        this.userModalAddUpdate.form.invoices.abroad.province =
          params.data.user.invoices.abroad.province;
        this.userModalAddUpdate.form.invoices.abroad.postCode =
          params.data.user.invoices.abroad.postCode;
        this.userModalAddUpdate.form.invoices.abroad.address =
          params.data.user.invoices.abroad.address;
      }

      this.userModalAddUpdate.form.permissions = params.data.user.permissions;

      this.userModalAddUpdate.modal = true;
      this.getAgentNotesTotal(params.data.user._id);
      this.couponsUserGet(params.data.user._id);
    },

    async getSettings() {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "admin/settings"
      );
      this.settings = response.data.settings;
    },

    async getUsers() {
      const id = this.$route.params.userId;

      let response = await axios.get(
        process.env.VUE_APP_API_URL + "admin/users/" + id
      );
      let userList = response.data.userlist;

      this.getUserDetail(response);
    },

    async userAdd() {
      this.$Progress.start();
      this.userModalAddUpdate.btnDisable = true;
      const oldBirthday = this.userModalAddUpdate.form.birthday;
      var birthdaySplit = this.userModalAddUpdate.form.birthday.split(".");
      var birthday = new Date(
        Date.UTC(
          birthdaySplit[2],
          birthdaySplit[1] - 1,
          birthdaySplit[0],
          0,
          0,
          0
        )
      );

      this.userModalAddUpdate.form.birthday = birthday.toISOString();

      delete this.userModalAddUpdate.form._id;
      delete this.userModalAddUpdate.form.phoneInput;
      try {
        var result = await axios.post(
          process.env.VUE_APP_API_URL + "admin/user/add/create",
          this.userModalAddUpdate.form,
          {
            withCredentials: true,
          }
        );
        this.userModalAddUpdate.btnDisable = false;

        this.$Progress.finish();
        this.againUserAdd(
          this.userModalAddUpdate.form.email,
          this.userModalAddUpdate.form.phone
        );
      } catch (error) {
        this.userModalAddUpdate.form.birthday = oldBirthday.replace(/\D/g, "");

        this.userModalAddUpdate.btnDisable = false;
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.errors[0].message,
        });
      }
    },

    async userUpdate() {
      this.$Progress.start();
      this.userModalAddUpdate.btnDisable = true; // Modal'daki post b utton'u disable ettik.

      var birthdaySplit = this.userModalAddUpdate.form.birthday.split(".");

      var birthday = [];
      _.forEach(birthdaySplit, (o) => {
        birthday.push(o.padStart(2, "0"));
      });

      birthday = _.toString(_.reverse(birthday)).replaceAll(",", "-");

      this.userModalAddUpdate.form.birthday = birthday;
      try {
        await axios.put(
          process.env.VUE_APP_API_URL +
            "admin/users/" +
            this.userModalAddUpdate.form._id,
          {
            birthday: new Date(
              this.userModalAddUpdate.form.birthday
            ).toISOString(),
            lastname: this.userModalAddUpdate.form.lastname,
            phone: this.userModalAddUpdate.form.phone,
            email: this.userModalAddUpdate.form.email,
            name: this.userModalAddUpdate.form?.name,
            tcId: this.userModalAddUpdate.form.tcId || "",
            defaultIdType: this.userModalAddUpdate.form.defaultIdType || 0,
            passportId: this.userModalAddUpdate.form.passportId || "",
          },
          {
            withCredentials: true,
          }
        );

        this.successSwal("Üye başarılı bir şekilde güncellendi.");
      } catch (error) {
        this.userModalAddUpdate.btnDisable = false;
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message || "Bir sorun oluştu!",
        });
      }
    },

    async invoicesUpdate() {
      this.$Progress.start();

      try {
        await axios.put(
          process.env.VUE_APP_API_URL +
            "admin/users/" +
            this.userModalAddUpdate.form._id,
          {
            invoices: this.userModalAddUpdate.form.invoices,
          },
          {
            withCredentials: true,
          }
        );

        this.successSwal("Üye başarılı bir şekilde güncellendi.");
      } catch (error) {
        this.userModalAddUpdate.btnDisable = false;
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message || "Bir sorun oluştu!",
        });
      }
    },

    async userPermsUpdate() {
      this.$Progress.start();
      try {
        await axios.put(
          process.env.VUE_APP_API_URL +
            "admin/users/" +
            this.userModalAddUpdate.form._id,
          {
            permissions: this.userModalAddUpdate.form.permissions,
            whereDidYouHear: this.userModalAddUpdate.form.whereDidYouHear,
          },
          {
            withCredentials: true,
          }
        );

        this.successSwal("Üye başarılı bir şekilde güncellendi.");
      } catch (error) {
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message || "Bir sorun oluştu!",
        });
      }
    },
    againUserAdd(email, phone) {
      Vue.swal
        .fire({
          icon: "warning",
          text: `Kullanıcının Cep telefonuna (${phone}) gönderilen SMS kodunu talep ediniz`,
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Gönder",
          denyButtonText: "Tekrar Kod Gönder",
          cancelButtonText: "Telefon No Değiştir",
          showLoaderOnConfirm: true,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: async (code) => {
            var params = {
              code,
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/confirm",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
          preDeny: async () => {
            var params = {
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/resend",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.successSwal("Üye başarılı bir şekilde eklendi.");
          } else if (result.isDenied) {
            this.againUserAdd(email, phone);
          } else if (result.isDismissed) {
            this.updatePhoneUserCreat(email);
          }
        });
    },

    updatePhoneUserCreat(email) {
      Vue.swal
        .fire({
          icon: "warning",
          text: "Kullanıcının Cep telefonuna güncelleyiniz",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Gönder",
          cancelButtonText: "vazgeç",
          showLoaderOnConfirm: true,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: async (phone) => {
            var params = {
              phone,
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/new-phone-resend",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.againUserAdd(email, result.value);
          } else if (result.isDismissed) {
            this.againUserAdd(email);
          }
        });
    },

    successSwal(text, refresh = true) {
      let timerInterval;
      Vue.swal
        .fire({
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result.dismiss === Vue.swal.DismissReason.timer) {
            refresh ? location.reload() : "";
          }
        });
    },

    async getAgentNotesTotal(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotesTotal = agentNotes.data.length;
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss">
.testtttt {
  z-index: 9999000;
}
.primary {
  background-color: #0c3c5e;
}
</style>
