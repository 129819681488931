<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Kampanyalar</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="RESERVATION_VENDOR_CAMPAIGN_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="dialog = true"
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>
          <CCardHeader class="topCardHeader">
            <div class="tabs mt-2">
              <button
                @click="tabClick(1, 'all')"
                :class="activeTab == 1 ? 'active' : ''"
              >
                Liste
              </button>
              <button
                @click="tabClick(2, 'all')"
                :class="activeTab == 2 ? 'active' : ''"
              >
                Slider Sırala
              </button>

              <button
                @click="tabClick(3, 'marketing')"
                :class="activeTab == 3 ? 'active' : ''"
              >
                Pazarlama Kampanyaları
              </button>
              <button
                @click="tabClick(4, 'custumerExperience')"
                :class="activeTab == 4 ? 'active' : ''"
              >
                Müşteri Deneyimi Kampanyaları
              </button>
              <button
                @click="tabClick(5, 'other')"
                :class="activeTab == 5 ? 'active' : ''"
              >
                Diğer Kampanyalar
              </button>
            </div>
          </CCardHeader>
          <CCardBody class="p-0">
            <ag-grid-vue
              v-if="activeTab == 1"
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              @rowDoubleClicked="editItem"
              @grid-ready="onGridReady"
            />
            <div v-if="activeTab == 2" class="campaign-sort">
              <div
                class="mt-4"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div v-for="(item, i) in campaignDragList" :key="i">
                  <drop @drop="handleDrop(item, ...arguments)">
                    <drag
                      class="sort-drag-campaign"
                      :transfer-data="{ item: item, example: 'rowData' }"
                      :class="{ [item]: true }"
                    >
                      <CBadge color="primary" class="mr-3">
                        {{ i + 1 }}
                      </CBadge>
                      {{ item.info.name[$store.state.locale] }}
                    </drag>
                  </drop>
                </div>
              </div>
              <div class="d-flex mb-3 mr-5 mt-4 ml-4">
                <CButton
                  class="mb-3 white--text"
                  @click="allUpdate()"
                  color="primary"
                >
                  Kaydet
                </CButton>
              </div>
            </div>
            <ag-grid-vue
              v-if="activeTab == 3"
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              @rowDoubleClicked="editItem"
              @grid-ready="onGridReady"
            />

            <ag-grid-vue
              v-if="activeTab == 4"
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              @rowDoubleClicked="editItem"
              @grid-ready="onGridReady"
            />
            <ag-grid-vue
              v-if="activeTab == 5"
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              @rowDoubleClicked="editItem"
              @grid-ready="onGridReady"
            />
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      fullscreen
      persistent
      no-click-animation
      style="z-index: 1031 !important"
      v-model="dialog"
      :retain-focus="false"
    >
      <v-card>
        <v-toolbar dense dark color="primary" fixed>
          <v-toolbar-title>Kampanyalar</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-btn
            dark
            text
            @click="copyItem(editedItem)"
            v-if="editedIndex > -1"
          >
            KOPYALA
          </v-btn>
          <RoleProvider slug="RESERVATION_VENDOR_CAMPAIGN_DELETE">
            <div slot-scope="{ canItPass }">
              <v-btn
                dark
                text
                @click="deleteItem()"
                v-if="editedIndex > -1 && canItPass"
              >
                SİL
              </v-btn>
            </div>
          </RoleProvider>

          <v-btn dark text @click="pasteCampaign()" v-if="!(editedIndex > -1)"
            >YAPIŞTIR</v-btn
          >
          <RoleProvider slug="RESERVATION_VENDOR_CAMPAIGN_UPDATE">
            <div slot-scope="{ canItPass }">
              <v-btn dark text @click="save()" v-if="canItPass">
                {{ editedIndex > -1 ? "DÜZENLE" : "EKLE" }}
              </v-btn>
            </div>
          </RoleProvider>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-5">
          <v-col cols="12" sm="2">
            <v-select
              v-model="editedItem.status"
              :items="[
                { value: 'active', status: 'Aktif' },
                { value: 'passive', status: 'Pasif' },
              ]"
              item-text="status"
              item-value="value"
              label="Durum"
              outlined
              dense
              hide-details
          /></v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="editedItem.webStatus"
              :items="[
                { value: 'active', webStatus: 'Aktif' },
                { value: 'passive', webStatus: 'Pasif' },
              ]"
              item-text="webStatus"
              item-value="value"
              label="Webde Göster"
              outlined
              dense
              hide-details
              @input="checkConfirmation"
          /></v-col>

          <v-col cols="12" sm="2">
            <v-select
              v-model="editedItem.style"
              :items="[
                { value: 'ribbon-style-1', style: 'Style 1' },
                { value: 'ribbon-style-2', style: 'Style 2' },
                { value: 'ribbon-style-3', style: 'Style 3' },
                { value: 'ribbon-style-frame', style: 'Kırmızı Çerçeve' },
              ]"
              item-text="style"
              item-value="value"
              label="Etiket Stili"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="editedItem.color"
              :items="[
                { value: 'ribbon-color-yellow', color: 'Sarı' },
                { value: 'ribbon-color-red', color: 'Kırmızı' },
                { value: 'ribbon-color-green', color: 'Yeşil' },
                { value: 'ribbon-color-blue', color: 'Mavi' },
                { value: 'ribbon-color-orange', color: 'Turuncu' },
              ]"
              item-text="color"
              item-value="value"
              label="Etiket Rengi"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-text-field
              outlined
              dense
              hide-details
              label="Arkaplan Rengi"
              v-model="editedItem.background"
            />
          </v-col>

          <v-col md="1">
            <v-text-field
              type="number"
              min="0"
              label="Min Gün"
              v-model="editedItem.minRentalDay"
              required
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="1">
            <v-text-field
              type="number"
              min="0"
              label="Max Gün"
              v-model="editedItem.maxRentalDay"
              required
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="2">
            <v-text-field
              type="number"
              min="0"
              label="Min Tutar"
              v-model="editedItem.minRentalAmount"
              required
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="2">
            <v-text-field
              type="number"
              min="0"
              label="Max Tutar"
              v-model="editedItem.maxRentalAmount"
              required
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="2">
            <v-autocomplete
              v-model="editedItem.couponId"
              :items="couponOptions"
              :value="editedIndex.couponId"
              item-text="label"
              :filter="customFilter"
              item-value="value"
              label="Promosyon Kodu"
              outlined
              dense
              hide-details
              clearable
              :menu-props="{ offsetY: true }"
            >
            </v-autocomplete>
            <small v-if="!editedItem.couponId"
              >Seçilmezse kampanya tüm aramalarda çalışır.</small
            >
          </v-col>

          <v-col md="2">
            <v-text-field
              label="Web'de Gözükecek Promosyon Kodu"
              v-model="editedItem.promoCode"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col md="2">
            <v-select
              v-model="editedItem.activateCampaignButton"
              :items="[
                { value: false, activateCampaignButton: 'Gizle' },
                { value: true, activateCampaignButton: 'Göster' },
              ]"
              :value="editedItem.activateCampaignButton"
              item-text="activateCampaignButton"
              item-value="value"
              label="Kampanyayı Aktif Et Butonu"
              outlined
              dense
            />
          </v-col>

          <v-col md="1">
            <v-select
              label="İndirim Tipi"
              v-model="editedItem.discountType"
              :items="[
                { value: 'rate', label: 'Yüzde' },
                { value: 'day', label: 'Gün' },
                { value: 'amount', label: 'Tutar' },
              ]"
              item-text="label"
              item-value="value"
              outlined
              dense
              hide-details
              @change="onChangeDiscountType()"
            />
          </v-col>

          <v-col md="1" v-if="editedItem.discountType == 'rate'">
            <v-text-field
              v-model="editedItem.discountRate"
              type="number"
              max="100"
              min="0"
              label="İndirim Yüzdesi"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="1" v-if="editedItem.discountType == 'day'">
            <v-text-field
              placeholder="0"
              v-model="editedItem.discountDay"
              type="number"
              min="0"
              label="Hediye Edilecek Gün"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="1" v-if="editedItem.discountType == 'amount'">
            <v-text-field
              placeholder="0"
              v-model="editedItem.discountAmount"
              type="number"
              min="0"
              label="İndirim Tutarı"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col md="2">
            <v-text-field
              label="Tedarikçi Katılım Oranı"
              v-model="editedItem.vendorReflection"
              type="number"
              max="100"
              min="0"
              outlined
              dense
              hide-details
              hint="Bu indirimin ne kadarına Tedarikçi katılacak? Katılmayacaksa 0 yazın. Tedarikçiden gelen tutar bu kurala göre ezilir ve mutabakatta bu fiyat baz alınır."
            />
          </v-col>

          <v-col md="2">
            <v-text-field
              label="Seçim Puanı"
              v-model="editedItem.selectPoint"
              type="number"
              max="100"
              min="0"
              outlined
              dense
              hide-details
              hint="Aynı veya benzer kampanyalar arasında seçim sırasını belirler. Örnek: bir araç 2 kampanyaya da giriyorsa puanı yüksek olan kampanya çalışacaktır. 100 en yüksek puan, 0 en düşük puan."
            />
          </v-col>

          <v-col md="2">
            <v-select
              v-model="editedItem.moveToTop"
              :items="[
                { value: 1, moveToTop: 'Hayır' },
                { value: 2, moveToTop: 'Evet' },
              ]"
              :value="editedItem.moveToTop"
              item-text="moveToTop"
              item-value="value"
              label="Kampanyalı Araçları En Üste Taşı"
              outlined
              dense
              hide-details
              hint="DİKKAT: Sadece aşağıdan GEÇERLİ ARAÇ seçtiğinizde kullanın. Tüm araçlar seçiliyken kullanmayın. Kampanyalı araçlar arama sonuçlarında en üste taşınır."
            />
          </v-col>

          <v-col md="2">
            <v-row no-gutters>
              <v-select
                v-model="editedItem.canPartnerUse"
                :items="[
                  { value: false, canPartnerUse: 'Hayır' },
                  { value: true, canPartnerUse: 'Evet' },
                ]"
                :value="editedItem.canPartnerUse"
                item-text="canPartnerUse"
                item-value="value"
                label="Partner Kullanabilir Mi?"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-row>
          </v-col>
          <v-col md="2">
            <v-select
              v-model="editedItem.group"
              :items="[
                { value: 'marketing', group: 'Pazarlama Kampanyası' },
                {
                  value: 'custumerExperience',
                  group: 'Müşteri Deneyimi Kampanyası',
                },
                { value: 'other', group: 'Diğer Kampanyalar' },
              ]"
              :value="editedItem.group"
              item-text="group"
              item-value="value"
              label="Kampanya Grubu"
              outlined
              dense
              hide-details
              hint="DİKKAT:Herhangi bir grubu seçmediğiniz takdirde diğer kampanyalar grubuna atanacaktır."
            />
          </v-col>
          <v-col md="2">
            <v-select
              v-model="editedItem.showOldPrice"
              :items="[
                { value: false, showOldPrice: 'Hayır' },
                { value: true, showOldPrice: 'Evet' },
              ]"
              :value="editedItem.showOldPrice"
              item-text="showOldPrice"
              item-value="value"
              label="İndirimsiz Fiyat Gösterilsin Mi?"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="2">
            <v-switch
              fluid
              dense
              v-model="editedItem.isSelectableFromSearchResult"
              label="Arama Sonuçlarında Seçilebilir"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch
              fluid
              dense
              v-model="editedItem.runDirectlyFromPartnerApi"
              label="Partner API'de Direk Çalıştır (Kuponsuz)"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="8" dense>
            <v-row>
              <v-col md="2">
                <v-checkbox
                  outlined
                  dense
                  hide-details
                  label="Banka Kampanyası"
                  v-model="editedItem.bankCampaign.status"
                />
              </v-col>
              <v-col md="2">
                <v-checkbox
                  outlined
                  dense
                  hide-details
                  label="İş Birliği Kampanyası"
                  v-model="editedItem.isCooperate"
                />
              </v-col>
              <v-col md="2">
                <v-checkbox
                  outlined
                  dense
                  hide-details
                  label="Saat Aralıklı Kampanya"
                  v-model="editedItem.timeIntervalCampaign"
                />
              </v-col>
              <v-col md="2">
                <v-select
                  v-model="editedItem.platform"
                  :items="platformOptions"
                  label="Platform"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col md="2" v-if="editedItem.bankCampaign.status">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  placeholder="VAKIFBANK VB."
                  label="Banka Adı"
                  v-model="editedItem.bankCampaign.issuerType"
                />
              </v-col>
              <v-col md="2" v-if="editedItem.bankCampaign.status">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  placeholder="PARAF-AXESS vb."
                  label="Kart Adı(Network)"
                  v-model="editedItem.bankCampaign.cardNetwork"
                />
              </v-col>
              <v-col v-if="editedItem.bankCampaign.status" md="2">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  placeholder="HalkbankPos vb."
                  label="Banka POS"
                  v-model="editedItem.bankCampaign.bankPos"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <CRow>
          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kampanya Başlangıç Tarihi"
              :date="''"
              resType="create"
              dateType="start"
            />

            <DateCheck
              v-else
              title="Kampanya Başlangıç Tarihi"
              :index="this.editedIndex"
              :date="this.editedItem.create.start"
              resType="create"
              dateType="start"
            />
          </CCol>

          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kampanya Bitiş Tarihi"
              :date="''"
              resType="create"
              dateType="end"
            />

            <DateCheck
              v-else
              title="Kampanya Bitiş Tarihi"
              :date="this.editedItem.create.end"
              resType="create"
              dateType="end"
            />
          </CCol>

          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kiralama Başlangıç Tarihi Alt Sınır"
              resType="pickup"
              dateType="start"
              :date="''"
            />

            <DateCheck
              v-else
              title="Kiralama Başlangıç Tarihi Alt Sınır"
              resType="pickup"
              dateType="start"
              :date="this.editedItem.pickup.start"
            />
          </CCol>

          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kiralama Başlangıç Tarihi Üst Sınır"
              :date="''"
              resType="pickup"
              dateType="end"
            />

            <DateCheck
              v-else
              title="Kiralama Başlangıç Tarihi Üst Sınır"
              :date="this.editedItem.pickup.end"
              resType="pickup"
              dateType="end"
            />
          </CCol>

          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kiralama Bitiş Tarihi Alt Sınır"
              :date="''"
              resType="dropoff"
              dateType="start"
            />

            <DateCheck
              v-else
              title="Kiralama Bitiş Tarihi Alt Sınır"
              :date="this.editedItem.dropoff.start"
              resType="dropoff"
              dateType="start"
            />
          </CCol>
          <CCol md="6" class="mb-3">
            <DateCheck
              v-if="this.editedIndex == -1"
              title="Kiralama Bitiş Tarihi Üst Sınır"
              :date="''"
              resType="dropoff"
              dateType="end"
            />
            <DateCheck
              v-else
              title="Kiralama Bitiş Tarihi Üst Sınır"
              :date="this.editedItem.dropoff.end"
              resType="dropoff"
              dateType="end"
            />
          </CCol>
          <CCol md="6" class="mb-3" v-if="editedItem.timeIntervalCampaign">
            <v-text-field
              v-if="editedIndex == -1"
              v-model="defaultItem.timeInterval.start"
              label="Saat Aralığı Başlangıç Saati"
              outlined
              dense
              hide-details
              type="time"
              class="mr-3"
            />

            <v-text-field
              v-else
              v-model="editedItem.timeInterval.start"
              label="Saat Aralığı Başlangıç Saati"
              outlined
              dense
              hide-details
              type="time"
              class="mr-3"
            />
          </CCol>
          <CCol md="6" class="mb-3" v-if="editedItem.timeIntervalCampaign">
            <v-text-field
              v-if="editedIndex == -1"
              v-model="defaultItem.timeInterval.end"
              label="Saat Aralığı Bitiş Saati"
              outlined
              dense
              hide-details
              type="time"
              class="mr-3"
            />
            <v-text-field
              v-else
              v-model="editedItem.timeInterval.end"
              label="Saat Aralığı Bitiş Saati"
              outlined
              dense
              hide-details
              type="time"
              class="mr-3"
            />
          </CCol>
        </CRow>

        <v-card class="ma-5 pa-3" elevation="10">
          <div class="pa-4">
            <h5>Pasif Tarihler</h5>
            <span class="text-caption"
              >Kampanyanın geçerli olmayacağı tarih aralıklarını
              girebilirsiniz.</span
            >
          </div>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="d-flex justify-space-between align-center">
                  <v-text-field
                    v-model="passiveDate.start"
                    label="Başlangıç Tarihi"
                    outlined
                    dense
                    hide-details
                    type="date"
                    class="mr-3"
                  />

                  <v-text-field
                    v-model="passiveDate.end"
                    label="Bitiş Tarihi"
                    outlined
                    dense
                    hide-details
                    type="date"
                    class="mr-3"
                  />

                  <v-btn
                    color="primary"
                    @click="addPassiveDate()"
                    :disabled="
                      passiveDate.start == '' ||
                      passiveDate.end == '' ||
                      moment(passiveDate.start).isAfter(passiveDate.end)
                    "
                  >
                    Ekle
                  </v-btn>
                </div>
              </v-col>

              <v-col>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Başlangıç Tarihi</th>
                        <th class="text-left">Bitiş Tarihi</th>
                        <th class="text-left">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in editedItem.passiveDates" :key="i">
                        <td>{{ moment(item.start).format("DD.MM.YYYY") }}</td>
                        <td>{{ moment(item.end).format("DD.MM.YYYY") }}</td>
                        <td>
                          <v-btn
                            icon
                            @click="deletePassiveDate(i)"
                            color="red"
                            small
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="pa-5">
          <v-dialog v-model="dialogSelecteds.status" width="500">
            <div class="container-selected-locations pa-2">
              <v-card class="pa-5">
                <v-card-title>Seçilen {{ dialogSelecteds.name }}</v-card-title>
                <v-card-content>
                  <v-list v-for="(item, i) in dialogSelecteds.items" :key="i">
                    <v-list-item>{{ item }}</v-list-item>
                  </v-list>
                </v-card-content>
              </v-card>
            </div>
          </v-dialog>
          <v-col md="6">
            <h5 class="mb-0">
              Geçerli Lokasyonlar
              <small
                class="primary pa-2 white--text rounded text-caption"
                style="cursor: pointer"
                v-if="editedItem?.locations?.length > 0"
                @click="selectedDialogSetter(0)"
                >Seçilenleri Göster</small
              >

              <small v-else style="color: red">(Tümü Seçili)</small>
            </h5>
            <small class="gray"><u>Tümü için boş bırakın!!!</u></small>

            <ag-grid-vue
              style="width: 100%; height: 450px"
              class="ag-theme-balham fp-table"
              :columnDefs="locationColumnDefs"
              :defaultColDef="defaultColDef"
              :rowData="locationList"
              rowSelection="multiple"
              :statusBar="statusBar"
              @selection-changed="locationSelectionChanged"
              @grid-ready="onGridReadyLocation"
            />
          </v-col>

          <v-col md="6">
            <h5 class="mb-0">
              Geçerli Araçlar
              <small
                class="primary pa-2 white--text rounded text-caption"
                style="cursor: pointer"
                v-if="editedItem?.cars?.length > 0"
                @click="selectedDialogSetter(2)"
                >Seçilenleri Göster</small
              >

              <small v-else style="color: red">(Tümü Seçili)</small>
            </h5>
            <small class="gray"><u>Tümü için boş bırakın!!!</u></small>
            <ag-grid-vue
              style="width: 100%; height: 450px"
              class="ag-theme-balham fp-table"
              :columnDefs="carColumnDefs"
              :defaultColDef="defaultColDef"
              :rowData="carList"
              rowSelection="multiple"
              :statusBar="statusBar"
              @selection-changed="carSelectionChanged"
              @grid-ready="onGridReadyCar"
            />
          </v-col>
          <v-col md="6">
            <h5 class="mb-0">
              Geçerli Tedarikçiler
              <small
                class="primary pa-2 white--text rounded text-caption"
                style="cursor: pointer"
                v-if="editedItem?.vendors?.length > 0"
                @click="selectedDialogSetter(1)"
                >Seçilenleri Göster
              </small>

              <small v-else style="color: red">(Tümü Seçili)</small>
            </h5>
            <small class="gray"><u>Tümü için boş bırakın!!!</u></small>
            <ag-grid-vue
              style="width: 100%; height: 450px"
              class="ag-theme-balham fp-table"
              :columnDefs="vendorColumnDefs"
              :defaultColDef="defaultColDef"
              :rowData="vendorList"
              rowSelection="multiple"
              :statusBar="statusBar"
              @selection-changed="vendorSelectionChanged"
              @grid-ready="onGridReadyVendor"
              :gridOptions="{
                suppressRowClickSelection: true,
                onCellValueChanged: vendorSelectionChanged,
              }"
            />
          </v-col>
          <v-col md="6">
            <h5 class="mb-0">
              Geçerli Partnerler
              <small
                class="primary pa-2 white--text rounded text-caption"
                style="cursor: pointer"
                @click="showAllowedPartners = !showAllowedPartners"
                >{{
                  selectedPartnerTitles.length > 0 &&
                  selectedPartnerTitles.length !== partnerList.length
                    ? `${selectedPartnerTitles.length} Seçili`
                    : "0 Seçili"
                }}
              </small>
            </h5>
            <div class="container-selected-locations">
              <v-row>
                <v-col :md="showAllowedPartners ? 8 : 12">
                  <ag-grid-vue
                    style="width: 100%; height: 450px"
                    class="ag-theme-balham fp-table mt-3"
                    :columnDefs="partnerColumnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="partnerList"
                    rowSelection="multiple"
                    :statusBar="statusBar"
                    @selection-changed="partnerSelectionChanged"
                    @grid-ready="onGridReadyPartner"
                  />
                </v-col>
                <v-col md="4" v-if="showAllowedPartners" class="mt-3">
                  Seçilen Partnerler:

                  <v-list
                    v-if="
                      selectedPartnerTitles.length === 0 ||
                      selectedPartnerTitles.length == partnerList.length
                    "
                  >
                    <li>0 Seçili</li>
                  </v-list>
                  <v-list
                    v-for="(item, i) in selectedPartnerTitles"
                    :key="i"
                    v-else
                  >
                    <li>
                      <small>{{ item }}</small>
                    </li>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row class="pa-5">
          <v-col md="4" class="mb-3" v-if="editedIndex > -1">
            <div>
              <!-- Bug Fixed: Kampanya görseli değiştirdikten sonra linki kırık görünüyor-->
              <h5>Mobil Fotoğraf</h5>
              <button
                v-if="editedItem.image.mobil"
                type="button"
                @click="editedItem.image.mobil = ''"
              >
                Sil
              </button>
            </div>
            <img
              v-if="editedItem.image.mobil.includes('data:image')"
              :src="`${editedItem.image.mobil}`"
              width="100%"
            />

            <img
              v-if="
                !editedItem.image.mobil.includes('data:image') &&
                editedItem.image.mobil !== ''
              "
              :src="`https://cdn.renticar.com/campaign/${editedItem.image.mobil}`"
              width="100%"
            />
            <input
              v-if="!editedItem.image.mobil"
              type="file"
              ref="fileMobil"
              custom
              v-on:change="selectImageMobil()"
            />
          </v-col>

          <v-col md="4" class="mb-3" v-if="editedIndex > -1">
            <div>
              <h5>Desktop Fotoğraf</h5>
              <button
                v-if="editedItem.image.desktop"
                type="button"
                @click="editedItem.image.desktop = ''"
              >
                Sil
              </button>
            </div>
            <img
              v-if="editedItem.image.desktop.includes('data:image')"
              :src="`${editedItem.image.desktop}`"
              width="100%"
            />
            <img
              v-if="
                !editedItem.image.desktop.includes('data:image') &&
                editedItem.image.desktop !== ''
              "
              :src="`https://cdn.renticar.com/campaign/${editedItem.image.desktop}`"
              width="100%"
            />
            <b>{{
              desktopSizeCheck
                ? null
                : "Lütfen 1150x250 boyutlarında görsel seçin"
            }}</b>

            <input
              v-if="!editedItem.image.desktop"
              type="file"
              id="input"
              ref="fileDesktop"
              custom
              v-on:change="selectImageDesktop()"
            />
          </v-col>

          <!-- Detay Sayfası İçin Görsel -->

          <v-col md="4" class="mb-3" v-if="editedIndex > -1">
            <div>
              <h5>Detay Fotoğraf</h5>
              <button
                v-if="editedItem.image.detail"
                type="button"
                @click="editedItem.image.detail = ''"
              >
                Sil
              </button>
            </div>
            <img
              v-if="editedItem.image.detail?.includes('data:image')"
              :src="`${editedItem.image.detail}`"
              width="100%"
            />

            <img
              v-if="
                !editedItem.image.detail?.includes('data:image') &&
                editedItem.image.detail !== ''
              "
              :src="`https://cdn.renticar.com/campaign/${editedItem.image.detail}`"
              width="100%"
            />
            <input
              v-if="!editedItem.image.detail"
              type="file"
              ref="fileDetail"
              custom
              v-on:change="selectImageDetail()"
            />
          </v-col>

          <v-col md="4" class="mb-3" v-if="editedIndex == -1">
            <h5>Mobil Fotoğraf</h5>
            <input
              type="file"
              ref="fileMobil"
              custom
              v-on:change="selectImageMobil()"
            />
          </v-col>

          <v-col md="4" class="mb-3" v-if="editedIndex == -1">
            <h5>Desktop Fotoğraf</h5>
            <b>{{
              desktopSizeCheck
                ? null
                : "Lütfen 1150x250 boyutlarında görsel seçin"
            }}</b>
            <input
              type="file"
              id="input"
              ref="fileDesktop"
              custom
              v-on:change="selectImageDesktop()"
            />
          </v-col>

          <v-col md="4" class="mb-3" v-if="editedIndex == -1">
            <h5>Detay Fotoğraf</h5>
            <input
              type="file"
              ref="fileDetail"
              custom
              v-on:change="selectImageDetail()"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12"><h5>Görsel Link</h5></v-col>

          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              label="Link (TR)"
              v-model="editedItem.photoLink.tr"
          /></v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              label="Link (EN)"
              v-model="editedItem.photoLink.en"
          /></v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              label="Link (DE)"
              v-model="editedItem.photoLink.de"
          /></v-col>
        </v-row>
        <v-divider />

        <v-row>
          <v-col class="container-selected-locations pa-2">
            <v-card class="pa-5">
              <v-col cols="10">
                <span class="font-weight-bold pb-2 mb-2">
                  {{ " Dil Seçimi : " }}
                </span>
                <v-btn-toggle
                  v-model="btnToggleLanguage"
                  borderless
                  mandatory
                  style="width: 90%"
                  active-class="secondary white--text  "
                >
                  <v-btn
                    small
                    style="width: 50%; border-radius: 50px 0 0 50px"
                    :class="{ red: !btnToggleLanguage }"
                    class="ls-0 text-capitalize font-weight-bold"
                  >
                    {{ "ingilizce" }}
                  </v-btn>
                  <v-btn
                    small
                    style="width: 50%; border-radius: 0 50px 50px 0"
                    :class="{ red: btnToggleLanguage }"
                    class="ls-0 text-capitalize font-weight-bold"
                  >
                    {{ "almanca" }}
                  </v-btn>
                </v-btn-toggle></v-col
              >
              <h2 class="text-center">Kampanya Detayları (TR)</h2>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Adı"
                  v-model="editedItem.info.name.tr"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  label="Kampanya SEO Slug"
                  hint="Kampanya sayfası linki için kullanılacaktır. Örn: renticar.com/kampanyalar/slug_ad , büyük küçük harf alt tire _ virgül gibi karakterler KULLANMAYIN. "
                  v-model="editedItem.slug.tr"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Etiketi"
                  hint="Araç listelerken gözükür."
                  v-model="editedItem.label.tr"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Site Başlık TR"
                  v-model="editedItem.siteTitle.tr"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12" class="mb-3">
                <ckeditor v-model="editedItem.info.description.tr" />
              </v-col>
            </v-card>
          </v-col>

          <v-col class="container-selected-locations pa-2">
            <v-card class="pa-5" v-if="!btnToggleLanguage">
              <h2 class="text-center">Kampanya Detayları (EN)</h2>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Adı"
                  v-model="editedItem.info.name.en"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  label="Kampanya SEO Slug"
                  hint="Kampanya sayfası linki için kullanılacaktır. Örn: renticar.com/kampanyalar/slug_ad , büyük küçük harf alt tire _ virgül gibi karakterler KULLANMAYIN. "
                  v-model="editedItem.slug.en"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Etiketi"
                  hint="Araç listelerken gözükür."
                  v-model="editedItem.label.en"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Site Başlık EN"
                  v-model="editedItem.siteTitle.en"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <ckeditor v-model="editedItem.info.description.en"></ckeditor>
              </v-col>
            </v-card>
            <v-card class="pa-5" v-else>
              <h2 class="text-center">Kampanya Detayları (DE)</h2>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Adı"
                  v-model="editedItem.info.name.de"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  label="Kampanya SEO Slug"
                  hint="Kampanya sayfası linki için kullanılacaktır. Örn: renticar.com/kampanyalar/slug_ad , büyük küçük harf alt tire _ virgül gibi karakterler KULLANMAYIN. "
                  v-model="editedItem.slug.de"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Kampanya Etiketi"
                  hint="Araç listelerken gözükür."
                  v-model="editedItem.label.de"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Site Başlık DE"
                  v-model="editedItem.siteTitle.de"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <ckeditor v-model="editedItem.info.description.de"></ckeditor>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import Vue from "vue";
import "vue-select/dist/vue-select.css";
import ckeditor from "ckeditor4-vue";
import moment from "moment";
import Repeater from "@/components/Singular/Repeater";
import draggable from "vuedraggable";
import { RoleProvider } from "../../provider";
import func from "../../func";
import { Drag, Drop } from "vue-drag-drop";
import DateCheck from "../../components/DateCheck.vue";
import { type } from "os";
Vue.use(ckeditor);

export default {
  name: "Search",
  components: {
    AgGridVue,
    Repeater,
    draggable,
    Drag,
    Drop,
    DateCheck,
    RoleProvider,
  },
  data() {
    return {
      campaignDragList: [],
      selectedPartnerTitles: [],
      dialogSelecteds: {
        status: false,
        name: "",
        items: [],
      },
      showAllowedPartners: false,
      dialogAllowedPartners: {
        status: false,
        name: "",
        items: [],
      },
      passiveDate: {
        start: "",
        end: "",
      },
      activeTab: 1,
      tab: null,
      gridApi: null,
      gridApiVendor: null,
      gridApiCar: null,
      gridApiPartner: null,
      gridApiLocation: null,
      vendorList: [],
      locationList: [],
      couponOptions: [],
      carList: [],
      partnerList: [],
      searchFilterInput: "",
      btnToggleLanguage: 0,
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            if (params.value === "active") return "Aktif";
            else if (params.value === "passive") return "Pasif";
          },
          cellStyle: (params) => {
            if (params.value === "active")
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === "passive")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
          },
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },
        {
          field: "info.name.tr",
          headerName: "Kampanya Adı",
        },
        {
          field: "create.start",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY -  HH:mm");
          },
          headerName: "Kampanya Başlangıç Tarihi",
          width: 120,
        },

        {
          field: "create.end",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY -  HH:mm");
          },
          headerName: "Kampanya Bitiş Tarihi",
          width: 120,
        },
        {
          field: "createdAt",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY-  HH:mm");
          },
          width: 120,
          headerName: "Kampanya Oluşturma Tarihi",
        },
        {
          valueGetter: (params) => {
            if (params.data.discountType == "day") {
              return "Gün";
            }
            if (params.data.discountType == "rate") {
              return "Yüzde";
            }
            if (params.data.discountType == "amount") {
              return "Tutar";
            }
          },
          field: "discountType",
          width: 100,
          headerName: "İndirim Tipi",
        },
        {
          field: "discountDay",
          width: 100,
          headerName: "İndirim Gün",
        },
        {
          field: "discountRate",
          width: 100,
          headerName: "İndirim Yüzde",
        },
        {
          field: "discountAmount",
          width: 100,
          headerName: "İndirim Tutar",
        },
        {
          field: "webStatus",
          cellRenderer: (params) => {
            return params?.value == "active" ? "Active" : "";
          },
          width: 60,
          headerName: "Web'de göster",
        },
        {
          field: "promoCode",
          cellRenderer: (params) => {
            return params?.value == "-" ? "" : params?.value;
          },
          width: 80,
          headerName: "Web. Promosyon Kodu",
        },
      ],
      vendorColumnDefs: [
        {
          field: "brand",
          headerName: "Tedarikçi",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sort: "asc",
        },
        {
          field: "discount",
          headerName: "İndirim",
          editable: true,
          valueParser: (params) => {
            let numberValue = Number(params.newValue);
            return isNaN(numberValue) ? params.oldValue : numberValue;
          },
          valueGetter: (params) => {
            return params.data.discount || 0;
          },
          checkboxSelection: false,
        },
        {
          field: "vendorReflection",
          headerName: "Katılım Oranı",
          editable: true,
          valueParser: (params) => {
            let numberValue = Number(params.newValue);
            return isNaN(numberValue) ? params.oldValue : numberValue;
          },
          valueGetter: (params) => {
            return params.data.vendorReflection || 0;
          },
          checkboxSelection: false,
        },
      ],
      locationColumnDefs: [
        {
          field: "locationName",
          headerName: "Lokasyon",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sort: "asc",
        },
      ],
      carColumnDefs: [
        {
          field: "brand",
          headerName: "Marka",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sort: "asc",
        },
        {
          field: "model",
          headerName: "Model",
        },
        {
          field: "fuel",
          headerName: "Yakıt",
        },
        {
          field: "transmission",
          headerName: "Vites",
        },
        {
          field: "class",
          headerName: "Sınıf",
        },
        {
          field: "body",
          headerName: "Kasa",
        },
      ],
      partnerColumnDefs: [
        {
          field: "title",
          headerName: "Partner Adı",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sort: "asc",
        },
        {
          field: "status",
          headerName: "Durum",
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      dialog: false,
      rowData: [],
      vendorItems: [],
      locationItems: [],
      platformOptions: [
        { text: "Web", value: "WEB" },
        { text: "Mobile", value: "MOBILE" },
        { text: "Hepsi", value: "ALL" },
      ],
      editedIndex: -1,
      campaignCopy: false,
      editedItem: {
        isSelectableFromSearchResult: false,
        runDirectlyFromPartnerApi: false,
        photoLink: {
          tr: "",
          en: "",
          de: "",
        },
        slug: {
          tr: "",
          en: "",
          de: "",
        },
        status: "",
        webStatus: "",
        promoCode: "",
        passiveDates: [],
        siteTitle: {
          tr: "Site Başlığı Türkçe",
          en: "Site Başlığı İngilizce",
          de: "Site Başlığı Almanca",
        },
        info: {
          name: {
            tr: "Kampanya Adı Türkçe",
            en: "Kampanya Adı İngilizce",
            de: "Kampanya Adı Almanca",
          },
          description: {
            tr: "Kampanya Detayı Türkçe",
            en: "Kampanya Detayı İngilizce",
            de: "Kampanya Detayı Almanca",
          },
        },

        vendors: [],

        pickup: {
          start: moment().format(),
          end: moment().format(),
        },
        dropoff: {
          start: moment().format(),
          end: moment().format(),
        },
        create: {
          start: moment().format(),
          end: moment().format(),
        },
        image: {
          mobil: "",
          desktop: "",
          detail: "",
        },
        timeInterval: {
          start: "",
          end: "",
        },
        background: "000000",
        discountType: "rate",
        discountRate: 0,
        discountDay: 0,
        discountAmount: 0,
        vendorReflection: 0,
        selectPoint: 0,
        moveToTop: 1,
        activateCampaignButton: false,
        canPartnerUse: false,
        showOldPrice: true, //Üstü çizili fiyat gösterilsin mi ?
        group: "other",
        minRentalDay: 0,
        maxRentalDay: 0,
        minRentalAmount: 0,
        maxRentalAmount: 1000000,
        label: {
          tr: "Kampanya Etiketi Türkçe",
          en: "Kampanya Etiketi İngilizce",
          de: "Kampanya Etiketi Almanca",
        },
        style: "ribbon-style-1",
        color: "ribbon-color-green",
        couponId: null,
        bankCampaign: {
          status: false,
          cardNetwork: "", //bonus
          bankPos: "", //halkbank,
          issuerType: "",
        },
        isCooperate: false,
        timeIntervalCampaign: false,
        platform: "ALL",
      },
      desktopSizeCheck: true,
      defaultItem: {
        photoLink: {
          tr: "",
          en: "",
          de: "",
        },
        slug: {
          tr: "",
          en: "",
          de: "",
        },
        status: "",
        webStatus: "",
        promoCode: "",
        siteTitle: {
          tr: "Site Başlığı Türkçe",
          en: "Site Başlığı İngilizce",
          de: "Site Başlığı Almanca",
        },
        info: {
          name: {
            tr: "Kampanya Adı Türkçe",
            en: "Kampanya Adı İngilizce",
            de: "Kampanya Adı Almanca",
          },
          description: {
            tr: "Kampanya Detayı Türkçe",
            en: "Kampanya Detayı İngilizce",
            de: "Kampanya Detayı Almanca",
          },
        },
        vendors: [],

        pickup: {
          start: moment().format(),
          end: moment().format(),
        },
        dropoff: {
          start: moment().format(),
          end: moment().format(),
        },
        create: {
          start: moment().format(),
          end: moment().format(),
        },
        timeInterval: {
          start: "",
          end: "",
        },
        image: {
          mobil: "",
          desktop: "",
          detail: "",
        },
        background: "000000",
        discountType: "rate",
        discountRate: 0,
        discountDay: 0,
        discountAmount: 0,
        vendorReflection: 0,
        selectPoint: 0,
        moveToTop: 1,
        activateCampaignButton: false,
        canPartnerUse: false,
        showOldPrice: true, //Üstü çizili fiyat gösterilsin mi ?
        group: "other",
        minRentalDay: 0,
        maxRentalDay: 0,
        minRentalAmount: 0,
        maxRentalAmount: 1000000,
        label: {
          tr: "Kampanya Etiketi Türkçe",
          en: "Kampanya Etiketi İngilizce",
          de: "Kampanya Etiketi Almanca",
        },
        style: "ribbon-style-1",
        color: "ribbon-color-green",
        couponId: null,
        bankCampaign: {
          status: false,
          cardNetwork: "", //bonus
          bankPos: "", //halkbank
        },
        isCooperate: false,
        timeIntervalCampaign: false,
        platform: "ALL",
      },
    };
  },
  watch: {
    "editedItem.canPartnerUse": function (val) {
      if (val) {
        this.dialogAllowedPartners = {
          status: true,
          title: "Kullanabilecek Partnerler",
        };
      }
    },
    dialog: function (val) {
      if (!val) {
        this.close();
      }
    },

    "editedItem.info.name": function (val) {
      if (
        val.length > 0
        // && this.editedIndex < 0 .
      ) {
        this.editedItem.slug = func.slugify(val);
      }
    },
  },
  computed: {
    sortOptions() {
      return Array.from(
        {
          length: this.rowData.filter((row) => row.webStatus == "active")
            .length,
        },
        (_, i) => i + 1
      );
    },

    formTitle() {
      return this.editedIndex === -1
        ? "Yeni Kampanya Oluştur"
        : "Kampanya Düzenle";
    },
  },
  async created() {
    await this.getCampaign("all");
    await this.getPartnerUsers();
  },
  mounted() {
    this.getVendors();
    this.getLocations();
    this.getCars();
    this.getCoupons();
  },

  methods: {
    addPassiveDate() {
      this.editedItem.passiveDates.push({
        start: this.passiveDate.start,
        end: this.passiveDate.end,
      });
      this.passiveDate.start = "";
      this.passiveDate.end = "";
    },

    deletePassiveDate(index) {
      this.editedItem.passiveDates.splice(index, 1);
    },
    customFilter(item, queryText, itemText) {
      if (!queryText) {
        return true;
      }
      return itemText.toLowerCase().startsWith(queryText.toLowerCase());
    },
    checkConfirmation() {
      if (this.editedItem.webStatus === "passive") {
        if (!confirm("WebStatus Pasif yapmayı onaylıyormusunuz?")) {
          this.editedItem.webStatus = "active";
        }
      }
    },
    async getPartnerUsers() {
      this.$Progress.start();
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner"
        );
        this.partnerList = data;
      } catch (error) {
      } finally {
        this.$Progress.finish();
      }
    },
    pasteCampaign() {
      this.editedIndex = 0;
      this.campaignCopy = true;
      this.editedItem = this.$store.state.copiedCampaign;
    },
    selectedDialogSetter(id) {
      switch (id) {
        case 0:
          let locations = [];
          this.locationList.map((loc) => {
            if (this.editedItem.locations.includes(loc.slug))
              locations.push(loc.locationName);
          });
          this.dialogSelecteds.items = locations;
          this.dialogSelecteds.name = "Lokasyonlar";
          this.dialogSelecteds.status = true;
          break;

        case 1:
          let vendors = [];
          this.vendorList.map((vendor) => {
            if (
              this.editedItem.vendors
                .map((v) => v.brandSlug)
                .includes(vendor.brandSlug)
            )
              vendors.push(vendor.brand);
          });
          this.dialogSelecteds.items = vendors;
          this.dialogSelecteds.name = "Tedarikçiler";
          this.dialogSelecteds.status = true;
          break;
        case 2:
          let cars = [];
          this.carList.map((car) => {
            if (this.editedItem.cars.includes(car._id))
              cars.push(
                `${car.brand} ${car.model} (${car.fuel} ${car.transmission} ${car.body})`
              );
          });
          this.dialogSelecteds.items = cars;
          this.dialogSelecteds.name = "Araçlar";
          this.dialogSelecteds.status = true;
          break;
        default:
          break;
      }
    },
    handleDrop(toList, data) {
      const fromList = data.item;
      if (fromList) {
        const to = this.campaignDragList.findIndex((i) => i.id == toList.id);
        const from = this.campaignDragList.findIndex(
          (i) => i.id == fromList.id
        );
        this.campaignDragList[to] = fromList;
        this.campaignDragList[from] = toList;
        this.$forceUpdate();
      }
    },
    vendorOnFirstDataRendered(params) {
      // set vendorList vendorReflection 0
      this.vendorList.map((vendor) => {
        vendor.vendorReflection = 0;
      });
      params &&
        params.forEachNode((node) =>
          node.setSelected(
            !!node.data &&
              this.editedItem?.vendors
                .map((v) => v.brandSlug)
                .includes(node.data.brandSlug)
          )
        );

      // map edititem vendors brandSlug and set vendorReflection
      this.editedItem.vendors.map((v) => {
        this.vendorList.map((vendor) => {
          if (v.brandSlug == vendor.brandSlug) {
            vendor.vendorReflection = v.vendorReflection;
            vendor.discount = v.discount;
          }
        });
      });

      this.gridApiVendor?.refreshCells();
    },
    vendorSelectionChanged(params) {
      this.editedItem.vendors = params.api.getSelectedRows().map((row) => {
        return {
          brandSlug: row.brandSlug,
          vendorReflection: row.vendorReflection,
          discount: row.discount,
        };
      });
    },
    locationOnFirstDataRendered(params) {
      params &&
        params.forEachNode((node) =>
          node.setSelected(
            !!node.data && this.editedItem?.locations?.includes(node.data.slug)
          )
        );
    },
    locationSelectionChanged(params) {
      this.editedItem.locations = params.api
        .getSelectedRows()
        .map((row) => row.slug);
    },
    carOnFirstDataRendered(params) {
      params &&
        params.forEachNode((node) =>
          node.setSelected(
            !!node.data && this.editedItem?.cars?.includes(node.data._id)
          )
        );
    },
    partnerOnFirstDataRendered(params) {
      params &&
        params.forEachNode((node) =>
          node.setSelected(
            !!node.data && this.editedItem?.partners?.includes(node.data._id)
          )
        );
    },
    carSelectionChanged(params) {
      this.editedItem.cars = params.api.getSelectedRows().map((row) => row._id);
    },

    partnerSelectionChanged(params) {
      this.editedItem.partners = params.api
        .getSelectedRows()
        .map((row) => row._id);

      this.selectedPartnerTitles = params.api
        .getSelectedRows()
        .map((row) => row.title);
    },
    tabClick(active, campaignGroup) {
      this.activeTab = active;
      this.getCampaign(campaignGroup);
    },
    selectImageMobil() {
      const _this = this;
      const fileImage = this.$refs.fileMobil.files[0];

      if (fileImage.type === "image/jpg" || fileImage.type === "image/jpeg") {
        let reader = new FileReader();

        reader.onload = function (e) {
          _this.editedItem.image.mobil = e.target.result;
        };
        reader.readAsDataURL(fileImage);
      } else {
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: "Yalnızca jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      }
    },

    selectImageDesktop() {
      const _this = this;
      const fileImage = this.$refs.fileDesktop.files[0];

      if (fileImage.type === "image/jpg" || fileImage.type === "image/jpeg") {
        let reader = new FileReader();
        var image = new Image();
        reader.onload = function (e) {
          image.src = reader.result;

          image.onload = function () {
            if (image.width !== 1150 || image.height !== 250) {
              _this.desktopSizeCheck = false;
              _this.editedItem.image.desktop = "";
              alert("Lütfen 1150x250 boyutlarında görsel seçin.");
            } else {
              _this.desktopSizeCheck = true;
              _this.editedItem.image.desktop = e.target.result;
            }
          };
        };

        reader.readAsDataURL(fileImage);
      } else {
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: "Yalnızca jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      }
    },

    selectImageDetail() {
      const _this = this;
      const fileImage = this.$refs.fileDetail.files[0];

      if (fileImage.type === "image/jpg" || fileImage.type === "image/jpeg") {
        let reader = new FileReader();

        reader.onload = function (e) {
          _this.editedItem.image.detail = e.target.result;
        };

        reader.readAsDataURL(fileImage);
      } else {
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: "Yalnızca jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      }
    },

    allVendors() {
      if (this.vendorItems.length == this.editedItem.vendors.length) {
        this.editedItem.vendors = [];
      } else {
        this.editedItem.vendors = [];
        this.vendorItems.forEach((vendor) => {
          this.editedItem.vendors.push(vendor.value);
        });
      }
    },
    allLocations() {
      if (this.locationItems.length == this.editedItem.locations.length) {
        this.editedItem.locations = [];
      } else {
        this.editedItem.locations = [];
        this.locationItems.forEach((location) => {
          this.editedItem.locations.push(location.value);
        });
      }
    },
    allCars() {
      if (this.carItems.length == this.editedItem.cars.length) {
        this.editedItem.cars = [];
      } else {
        this.editedItem.cars = [];
        this.carItems.forEach((car) => {
          this.editedItem.cars.push(car.value);
        });
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridReadyVendor(params) {
      this.gridApiVendor = params.api;
      this.vendorOnFirstDataRendered(params.api);
      params.api.sizeColumnsToFit();
    },
    onGridReadyLocation(params) {
      this.gridApiLocation = params.api;
      this.locationOnFirstDataRendered(params.api);
      params.api.sizeColumnsToFit();
    },
    onGridReadyCar(params) {
      this.gridApiCar = params.api;
      this.carOnFirstDataRendered(params.api);
      params.api.sizeColumnsToFit();
    },

    onGridReadyPartner(params) {
      this.gridApiPartner = params.api;
      this.partnerOnFirstDataRendered(params.api);
      params.api.sizeColumnsToFit();
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    onChangeDiscountType() {
      this.editedItem.discountRate = 0;
      this.editedItem.discountDay = 0;
      this.editedItem.discountAmount = 0;
    },
    getCoupons() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/coupons")
        .then((response) => {
          if (response.data.length > 0) {
            this.couponOptions = [];

            response.data.forEach((value, key) => {
              this.couponOptions.push({
                label: value.name,
                value: value._id,
              });
            });
          }
        });
    },
    getVendors() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor?status=true")
        .then((response) => {
          var array = response.data.vendorList;
          this.vendorList = response.data.vendorList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].brand,
              value: array[index].brandSlug,
            };
            result.push(item);
          }
          this.vendorItems = result;
        });
    },
    getLocations() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/location?status=true")
        .then((response) => {
          var array = response.data;
          this.locationList = response.data.locationList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].name,
              value: array[index]._id,
            };
            result.push(item);
          }
          this.locationItems = result;
        });
    },
    getCars() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/cars?status=true")
        .then((response) => {
          var array = response.data.carList;
          this.carList = response.data.carList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].name,
              value: array[index]._id,
            };
            result.push(item);
          }
          this.carItems = result;
        });
    },

    async getCampaign(campaignGroup) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_URL +
            "admin/campaign" +
            `?group=${campaignGroup}`
        );
        let now = this.$moment();
        this.rowData = response.data.sort((a, b) => {
          return now.diff(a.create.start) - now.diff(b.create.start);
        });

        this.rowData = response.data;
        this.campaignDragList = response.data
          .filter((row) => row.webStatus == "active" && row.status == "active")
          .sort((p1, p2) =>
            p1.index > p2.index ? 1 : p1.index < p2.index ? -1 : 0
          );
      } catch (e) {}
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor?status=true")
        .then((response) => {
          var array = response.data.vendorList;
          this.vendorList = response.data.vendorList;
          var result = [];
          for (let index = 0; index < array.length; index++) {
            const item = {
              label: array[index].brand,
              value: array[index].brandSlug,
            };
            result.push(item);
          }
          this.vendorItems = result;
        });
    },

    editItem(params) {
      this.editedIndex = this.rowData.indexOf(params.node.data);

      console.log("asfd", this.editedIndex);

      this.campaignCopy = false;
      this.editedItem = Object.assign({}, params.node.data);

      console.log(this.editedItem.info.name.tr);

      if (!this.editedItem.bankCampaign)
        this.editedItem.bankCampaign = this.defaultItem.bankCampaign;

      if (!this.editedItem.timeInterval) {
        this.editedItem.timeInterval = {
          start: "",
          end: "",
        };
      }

      this.locationOnFirstDataRendered(this.gridApiLocation);
      this.vendorOnFirstDataRendered(this.gridApiVendor);
      this.carOnFirstDataRendered(this.gridApiCar);
      this.partnerOnFirstDataRendered(this.gridApiPartner);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.campaignCopy = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.$store.dispatch("setDefaultCampaignDates");
      });
    },

    async copyItem(campaign) {
      this.$store.commit("setCopiedCampaign", campaign);
      this.campaignCopy = true;
      Vue.swal.fire({
        icon: "success",
        text: "Kampanya Kopyalandı",
        showCloseButton: true,
      });
      this.dialog = false;
    },

    async deleteItem() {
      if (this.campaignCopy === true) {
        this.close();
      } else {
        Vue.swal
          .fire({
            icon: "question",
            text: "Silmek istiyor musunuz?",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
            showCloseButton: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await axios.delete(
                  process.env.VUE_APP_API_URL + "admin/campaign",
                  {
                    data: {
                      _id: this.editedItem.id,
                    },
                    withCredentials: true,
                  }
                );
                location.reload();
              } catch (e) {}
            }
          });
      }
    },

    async save($event) {
      this.editedItem.create = this.$store.state.campaignDates.create;
      this.editedItem.pickup = this.$store.state.campaignDates.pickup;
      this.editedItem.dropoff = this.$store.state.campaignDates.dropoff;
      if (this.editedIndex > -1 && !this.campaignCopy) {
        console.log("oldu");
        delete this.editedItem.createdAt;
        delete this.editedItem.updatedAt;
        delete this.editedItem.index;
        delete this.editedItem.id;
        delete this.editedItem.vendorCommission;
        await axios
          .put(process.env.VUE_APP_API_URL + "admin/campaign", {
            find: {
              id: this.rowData[this.editedIndex].id,
            },
            update: this.editedItem,
          })
          .then((response) => {
            location.reload();
          })
          .catch((e) => {
            const message = JSON.stringify(e.response.data.errors[0]["message"])
              .replace(/\\\"/g, "")
              .replaceAll('"', "");
            Vue.swal({
              icon: "error",
              title: "Kampanya Düzenle",
              html: message,
              confirmButtonText: "Tamam",
            });
          });
      } else {
        this.campaignCopy = false;
        await axios
          .post(process.env.VUE_APP_API_URL + "admin/campaign", this.editedItem)
          .then(() => {
            location.reload();
          })
          .catch((e) => {
            const message = JSON.stringify(e.response.data.errors[0]["message"])
              .replace(/\\\"/g, "")
              .replaceAll('"', "");
            const x = Vue.swal({
              icon: "error",
              title: "Kampanya Düzenle",
              html: message,
              confirmButtonText: "Tamam",
            });
          });
      }
    },
    async allUpdate() {
      const body = this.campaignDragList;
      await axios
        .put(process.env.VUE_APP_API_URL + "admin/campaign/allUpdate", body)
        .then((response) => {
          location.reload();
        })
        .catch((e) => {
          Vue.swal({
            icon: "error",
            title: "Kampanya Düzenle",
            html: e.response.data.errors[0].message,
            confirmButtonText: "Tamam",
          });
        });
    },
  },
};
</script>

<style>
/** */
.sort-drag-campaign {
  background-color: rgba(230, 230, 230, 0.973);
  margin-bottom: 1rem;
  padding: 1rem;
  width: 80%;
  margin-left: 1rem;
}

.swal2-container {
  z-index: 1000002 !important;
}

.tabs button {
  padding: 0.2rem 1rem;
  outline: none !important;
  border: 0;
  font-size: 16px;
  background: transparent !important;
  border: 1px solid white;
  border-bottom: 0;
  color: white;
}

.button {
  width: 180px;
}

.tabs button.active {
  height: 5ex;
}

.topCardHeader {
  padding-bottom: 0 !important;
}
</style>
