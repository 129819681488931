<template>
  <v-row class="dashboard-row">
    <v-col cols="12" sm="12" v-if="!loading">
      <v-row class="ma-2 d-flex justify-space-between align-center">
        <!-- Rezervasyon arama -->
        <v-input-group class="reservation-search">
          <v-text-field
            v-model="reservationNo"
            placeholder="Rezervasyon No"
            outlined
            dense
            hide-details
            @keyup.enter="goToReservation"
            maxlength="6"
            @input="handleInput"
            style="text-transform: uppercase"
          ></v-text-field>
          <v-btn
            color="primary"
            small
            @click="goToReservation"
            :disabled="!isValidReservationNo"
            class="search-btn"
          >
            {{ $t('go_button') }}
          </v-btn>
        </v-input-group>

        <!-- Mevcut date-range-picker -->
        <date-range-picker
          v-model="searchBody"
          ref="picker"
          opens="left"
          singleDatePicker="range"
          :alwaysShowCalendars="false"
          :style="!isMobile() ? 'min-width: 350px' : ''"
          :ranges="ranges"
        >
          <template>
            {{ searchBody.startDate | date }} -
            {{ searchBody.endDate | date }}
          </template>
        </date-range-picker>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="status-card" elevation="0">
            <v-card-text class="pa-4">
              <div class="d-flex flex-md-row flex-column mb-4">
                <!-- Toplam Gerçekleşen KPI -->
                <router-link
                  :to="`/reservation/index/?startDate=${moment(searchBody.startDate)
                    .add(3, 'hours')
                    .format('YYYY-MM-DD')}&&endDate=${moment(
                    searchBody.endDate
                  ).format('YYYY-MM-DD')}&&status=${getTotalGerceklesenItem().statusies.join(',')}`"
                  class="text-decoration-none flex-grow-1 mb-md-0 mb-4 mr-md-4"
                >
                  <v-card class="kpi-card" elevation="0">
                    <v-card-text class="pa-4">
                      <div class="d-flex align-center mb-2">
                        <v-icon color="#10b981" size="20" class="mr-2">mdi-check-circle</v-icon>
                        <span class="kpi-title">{{ getTotalGerceklesenItem().status.name }}</span>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <span class="kpi-amount">{{ getTotalGerceklesenItem().price }}</span>
                        <span class="kpi-count success">{{ getTotalGerceklesenItem().length }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>

                <!-- Toplam İptal KPI -->
                <router-link
                  :to="`/reservation/index/?startDate=${moment(searchBody.startDate)
                    .add(3, 'hours')
                    .format('YYYY-MM-DD')}&&endDate=${moment(
                    searchBody.endDate
                  ).format('YYYY-MM-DD')}&&status=${getTotalIptalItem().statusies}`"
                  class="text-decoration-none flex-grow-1"
                >
                  <v-card class="kpi-card" elevation="0">
                    <v-card-text class="pa-4">
                      <div class="d-flex align-center mb-2">
                        <v-icon color="#dc2626" size="20" class="mr-2">mdi-cancel</v-icon>
                        <span class="kpi-title">{{ getTotalIptalItem().status.name }}</span>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <span class="kpi-amount">{{ getTotalIptalItem().price }}</span>
                        <span class="kpi-count error">{{ getTotalIptalItem().length }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
              </div>

              <v-divider class="mb-4"></v-divider>

              <v-row>
                <v-col 
                  v-for="status in getSubStatuses()" 
                  :key="status.status.name"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <div 
                    class="sub-status-card"
                    @click="navigateToStatus(status)"
                  >
                    <div class="d-flex align-center mb-2">
                      <v-icon :color="getStatusColor(status.status.name)" size="18" class="mr-2">
                        {{ getStatusIcon(status.status.name) }}
                      </v-icon>
                      <span class="sub-status-title">{{ status.status.name }}</span>
                    </div>
                    <div class="sub-status-values">
                      <span class="sub-status-count">{{ status.length }}</span>
                      <span class="sub-status-price">{{ status.price }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
     
      <div v-if="!isPartner">
        <v-row>
          <v-col cols="12">
            <v-card class="dashboard-stats-card" elevation="0">
              <!-- Masaüstü görünüm -->
              <div class="d-none d-md-block">
                <div class="stats-header">
                  <div v-for="(section, index) in sections" :key="index" class="header-item">
                    <v-icon color="#1e40af" class="header-icon">{{ section.icon }}</v-icon>
                    {{ $t(section.title) }}
                  </div>
                </div>
                <div class="stats-content">
                  <!-- Bildirimler -->
                  <div class="stats-section">
                    <div class="stats-items">
                      <div
                        v-for="(item, idx) in infos"
                        :key="idx"
                        class="stat-item"
                        @click="clickInfo(item)"
                      >
                        <CIcon v-if="item.icon" :name="item.icon" class="stat-icon" />
                        <span class="stat-label">{{ item.name }}</span>
                        <span class="stat-value">{{ item.count }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Fatura Durumu 
                  <div class="stats-section">
                    <div class="stats-items">
                      <router-link
                        v-for="(item, idx) in fullCreditBillingStatus"
                        :key="idx"
                        class="stat-item"
                        :to="'/reservation/index/?accountingStatus=' + item._id"
                      >
                        <span class="stat-label">{{ $t('accountingStatus.' + item._id) }}</span>
                        <span class="stat-value">{{ item.count }}</span>
                      </router-link>
                    </div>
                  </div>

                  <!-- Fatura Aktarımı -->
                  <div class="stats-section">
                    <div class="stats-items">
                      <div
                        v-for="(item, idx) in invoices"
                        :key="idx"
                        class="stat-item"
                        @click="clickInfo(item)"
                      >
                        <CIcon :name="item.icon" class="stat-icon" />
                        <span class="stat-label">{{ item.name }}</span>
                        <span class="stat-value">{{ item.count }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Ödeme Durumu -->
                  <div class="stats-section">
                    <div class="stats-items">
                      <div
                        v-for="(item, idx) in paymentStatus"
                        :key="idx"
                        class="stat-item"
                        @click="clickInfo(item)"
                      >
                        <CIcon :name="item.icon" class="stat-icon" />
                        <span class="stat-label">{{ item.name }}</span>
                        <span class="stat-value">{{ item.count }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Mobil görünüm -->
              <div class="d-md-none">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(section, index) in sections"
                    :key="index"
                  >
                    <v-expansion-panel-header class="mobile-header">
                      <div class="d-flex align-center">
                        <v-icon color="#1e40af" class="header-icon">{{ section.icon }}</v-icon>
                        {{ $t(section.title) }}
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="stats-items">
                        <div
                          v-for="(item, idx) in getSectionData(section.key)"
                          :key="idx"
                          class="stat-item"
                          @click="section.action ? section.action(item) : null"
                        >
                          <CIcon v-if="item.icon" :name="item.icon" class="stat-icon" />
                          <span class="stat-label">
                            {{ item.name || $t('accountingStatus.' + item._id) }}
                          </span>
                          <span class="stat-value">{{ item.count }}</span>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12" lg="8">
          <BarChart
            :queries="searchBody"
            :labels="vendorLabels"
            :datasets="datasets"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <DonutChart
            :reservation-data="reservationData"
          />
        </v-col>
      </v-row>

      <v-card v-if="!isPartner" elevation="0" class="mt-7 mx-2">
        <v-toolbar color="#003d6a" dark dense>
          <v-toolbar-title> {{ $t("last_search") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="getNowSearch()">
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn> -->
        </v-toolbar>

        <v-simple-table fixed-header height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Alış</th>
                <th class="text-left">İade</th>
                <th class="text-center">Partner</th>
                <th class="text-center">Araç</th>
                <th class="text-right">Arama</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in nowSearchRowData" :key="item._id">
                <td>
                  <strong>{{ item.pickup_location_name }}</strong>
                  <br /><small>{{
                    moment(item.pickup_datetime).format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td>
                  <strong>{{ item.return_location_name }}</strong>
                  <br /><small>{{
                    moment(item.return_datetime).format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td class="text-center text-caption">
                  {{ item.parameters?.partnerBrand }}
                </td>
                <td class="text-center">{{ item.resultCarCount }}</td>
                <td class="text-right">
                  <small>{{
                    moment(item.createdAt).local().format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td class="text-right">
                  <a
                    :href="
                      APP_URL +
                      'arac-kiralama/' +
                      item.pickup_location +
                      '/ara?sID=' +
                      item._id
                    "
                    target="_blank"
                    ><v-icon color="primary">mdi-arrow-right</v-icon></a
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    
    </v-col>

    <div class="w-100" v-else>
      <gogo-loading />
    </div>
  </v-row>
</template>

<script>
import axios from "axios";
import GogoLoading from "../components/GogoLoading.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import BarChart from "../components/Dashboard/Chart.vue";
import LocationInfos from "../components/Dashboard/LocationInfos.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import store from "../store";
import moment from "moment";
import DonutChart from "../components/Dashboard/DonutChart.vue";
moment.locale("tr");
export default {
  name: "Dashboard",
  components: {
    GogoLoading,
    vSelect,
    DateRangePicker,
    BarChart,
    LocationInfos,
    DonutChart,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("en-US").format(date);
    },
  },
  mounted() {
    store.getters.socket.on("SEARCH_LOG", (data) => {
      this.addRowData(data);
    });
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    let today = new Date();
    today.setHours(3, 0, 0, 0);
    let todayEnd = new Date();
    todayEnd.setHours(11, 59, 59, 999);

    let yesterdayStart = new Date();
    yesterdayStart.setDate(today.getDate() - 1);
    yesterdayStart.setHours(3, 0, 0, 0);

    let yesterdayEnd = new Date();
    yesterdayEnd.setDate(today.getDate() - 1);
    yesterdayEnd.setHours(11, 59, 59, 999);

    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1, 3);
    let thisMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      11,
      59,
      59,
      999
    );
    return {
      APP_URL: process.env.VUE_APP_URL,
      ranges: {
        [this.$t("today")]: [today, todayEnd],
        [this.$t("yesterday")]: [yesterdayStart, yesterdayEnd],
        [this.$t("this_month")]: [thisMonthStart, thisMonthEnd],
        [this.$t("this_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
        ],
        [this.$t("last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
        ],
      },

      // now search props
      nowSearchRowData: [],
      //
      options: {
        vendors: [],
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      //charts props
      reservationData: [],
      vendorLabels: [],
      datasets: [],
      infos: [],
      invoices: [],
      paymentStatus: [],
      fullCreditBillingStatus: [],
      //charts pro ps

      loading: false,
      searchBody: {
        vendor: "",
        startDate,
        endDate,
      },
      sections: [
        {
          title: 'notifications',
          icon: 'mdi-bell-outline',
          key: 'infos',
          action: this.clickInfo
        },
        // {
        //   title: 'fullCredit_billing_status',
        //   icon: 'mdi-file-document-outline',
        //   key: 'fullCreditBillingStatus'
        // },
        {
          title: 'invoices_transfer',
          icon: 'mdi-file-upload-outline',
          key: 'invoices',
          action: this.clickInfo
        },
        {
          title: 'payment_transfer',
          icon: 'mdi-bank-transfer',
          key: 'paymentStatus',
          action: this.clickInfo
        }
      ],
      reservationNo: '',
    };
  },
  computed: {
    isPartner() {
      return this.$store.state.GetServerData?.role == "PARTNER";
    },
    isValidReservationNo() {
      return this.reservationNo && this.reservationNo.length === 6;
    }
  },
  watch: {
    "searchBody.startDate": function (value) {
      this.getReservationData();
    },
    "searchBody.vendor": function (value) {
      this.getReservationData();
    },
    "searchBody.endDate": function (value) {
      this.getReservationData();
      return value;
    },
    "$i18n.locale": function (value) {
      window.location.reload();
    },
  },
  created() {
    this.getReservationData();
    this.itemsVendor();
    this.getNowSearch();
    window.dataLayer?.push({
      event: "myEvent",
    });
  },
  methods: {
    addRowData(data) {
      this.nowSearchRowData = [data, ...this.nowSearchRowData];
    },
    async getNowSearch() {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/dashboard/nowSearch",
          {
            withCredentials: true,
          }
        );
        this.nowSearchRowData = data;
      } catch (error) {}
    },
    clickInfo(info) {
      let routeData = this.$router.resolve(info?.route || {});

      window.open(routeData.href, "_blank");
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    async getReservationData() {
      this.loading = true;
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/dashboard",
          this.searchBody,
          {
            withCredentials: true,
            headers: {
              lang: this.$store.state.locale || "tr",
            },
          }
        );
        this.reservationData = data.reservations;
        this.vendorLabels = data.labels;
        this.datasets = data.datasets;
        this.infos = data.infos;
        this.invoices = data.invoicesStatus;
        this.fullCreditBillingStatus = data.fullCreditBillingStatus;
        this.paymentStatus = data.paymentStatus;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async itemsVendor() {
      try {
        let vendors = await axios.get(
          process.env.VUE_APP_API_URL + "admin/vendor?sort=1",
          {
            withCredentials: true,
          }
        );
        this.options.vendors = vendors.data.vendorList;
      } catch (error) {}
    },
    getStatusColor(status) {
      const colors = {
        'Gelecek': '#3b82f6',      // blue
        'Devam Eden': '#f59e0b',   // amber
        'Geçmiş': '#64748b',       // slate
        'Erken İade': '#06b6d4',   // cyan
        'Toplam İptal': '#ef4444', // red
        'Toplam Gerçekleşen': '#10b981' // emerald
      };
      return colors[status] || '#64748b';
    },

    getStatusIcon(status) {
      const icons = {
        'Gelecek': 'mdi-calendar-clock',
        'Devam Eden': 'mdi-car',
        'Geçmiş': 'mdi-calendar-check',
        'Erken İade': 'mdi-calendar-remove',
        'Toplam İptal': 'mdi-calendar-remove',
        'Toplam Gerçekleşen': 'mdi-check-circle'
      };
      return icons[status] || 'mdi-calendar';
    },
    getSectionData(key) {
      return this[key] || [];
    },
    // Toplam Gerçekleşen ve alt durumlarını hesapla
    getSubStatuses() {
      return this.reservationData.filter(item => 
        ['Gelecek', 'Devam Eden', 'Geçmiş', 'Erken İade'].includes(item.status.name)
      );
    },

    getTotalGerceklesenItem() {
      return this.reservationData.find(item => item.status.name === 'Toplam Gerçekleşen') || {
        status: { name: 'Toplam Gerçekleşen' },
        length: 0,
        price: '₺ 0,00',
        statusies: []
      };
    },

    getTotalIptalItem() {
      return this.reservationData.find(item => item.status.name === 'Toplam İptal') || {
        status: { name: 'Toplam İptal' },
        length: 0,
        price: '₺ 0,00',
        statusies: []
      };
    },

    formatPrice(price) {
      return parseFloat(price || 0)
        .toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ₺';
    },

    navigateToStatus(item) {
      const url = `/reservation/index/?startDate=${moment(this.searchBody.startDate).format('YYYY-MM-DD')}&&endDate=${moment(this.searchBody.endDate).format('YYYY-MM-DD')}&&status=${item.statusies}`;
      this.$router.push(url);
    },

    navigateToCancelled() {
      const cancelled = this.reservationData.find(item => item.status.name === 'Toplam İptal');
      if (cancelled) {
        const url = `${process.env.VUE_APP_ADMIN_URL}reservation/index/?startDate=${moment(this.searchBody.startDate).format('YYYY-MM-DD')}&&endDate=${moment(this.searchBody.endDate).format('YYYY-MM-DD')}&&status=${cancelled.statusies}`;
        window.open(url, '_blank');
      }
    },

    getStatusData(statusName) {
      return this.reservationData.find(item => item.status.name === statusName) || { length: 0, price: 0 };
    },

    getCompletedStatusIds() {
      return this.getCompletedSubStatuses()
        .map(status => status.statusies)
        .join(',');
    },

    getCancelledStatusIds() {
      const cancelled = this.reservationData.find(item => item.status.name === 'Toplam İptal');
      return cancelled ? cancelled.statusies : '';
    },
    goToReservation() {
      if (this.reservationNo) {
        this.$router.push(`/reservation/detail/${this.reservationNo}`);
      }
    },
    handleInput(value) {
      this.reservationNo = value.toUpperCase();
    }
  },
};
</script>
<style lang="scss" scoped>
.metric-card {
  background: white;
  border-radius: 16px !important;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  }

  .metric-title {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .metric-value {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 16px;
    color: #2c3e50;
  }

  .metric-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .metric-amount {
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
    }

    .metric-change {
      font-size: 0.75rem;
      font-weight: 500;
      display: flex;
      align-items: center;

      &.success {
        color: #4caf50;
      }

      &.error {
        color: #ff5252;
      }
    }
  }
}

// Mobile optimizations
@media (max-width: 600px) {
  .metric-card {
    .metric-value {
      font-size: 2rem;
    }

    .metric-title {
      font-size: 0.8125rem;
    }
  }
}

.dashboard-row {
  background-color: #f0f0f0;
}

.notification-list {
  .notification-item {
    padding: 12px 0;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .notification-content {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .notification-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: #666;
  }

  .notification-name {
    flex: 1;
    font-size: 0.875rem;
    color: #2c3e50;
  }

  .notification-count {
    font-weight: 600;
    color: #2c3e50;
    margin-left: 12px;
  }
}

// Progress bar özelleştirmesi
::v-deep .v-progress-linear {
  border-radius: 4px;
  
  .v-progress-linear__determinate {
    border-radius: 4px;
  }
}

.status-grid {
  display: grid;
  gap: 8px;
}

.status-item {
  padding: 12px 16px;
  background: #f8f9fa;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f1f3f5;
    transform: translateX(4px);
  }

  .status-icon {
    width: 18px;
    height: 18px;
    color: #666;
    margin-right: 12px;
  }

  .status-name {
    font-size: 0.875rem;
    color: #2c3e50;
    font-weight: 500;
  }

  .status-count {
    font-weight: 600;
    color: #2c3e50;
    background: white;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
}

// Responsive düzenlemeler
@media (max-width: 600px) {
  .status-item {
    padding: 10px 12px;
    
    .status-name {
      font-size: 0.8125rem;
    }
    
    .status-count {
      padding: 3px 10px;
      font-size: 0.8125rem;
    }
  }
}

.dashboard-stats-card {
  background: white;
  border-radius: 8px !important;
  border: 1px solid #e2e8f0;
  
  .stats-header {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
    background: #f8fafc;
    
    .header-item {
      flex: 1;
      padding: 16px;
      font-weight: 600;
      font-size: 14px;
      color: #1e40af;
      display: flex;
      align-items: center;
      min-width: 250px;
      
      .header-icon {
        margin-right: 8px;
        font-size: 20px;
      }
      
      &:not(:last-child) {
        border-right: 1px solid #e2e8f0;
      }
    }
  }
  
  .stats-content {
    display: flex;
    padding: 16px;
    gap: 24px;
    position: relative;

    .stats-section {
      position: relative;
      
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: -12px;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: #e2e8f0;
      }
    }
  }
}

.stats-section {
  flex: 1;
  min-width: 250px;
}

.stats-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stat-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: #f8fafc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;

  &:hover {
    background: #f1f5f9;
    transform: translateX(2px);
  }

  .stat-icon {
    width: 16px;
    height: 16px;
    color: #64748b;
    margin-right: 8px;
  }

  .stat-label {
    flex: 1;
    font-size: 13px;
    color: #334155;
  }

  .stat-value {
    font-weight: 600;
    color: #0f172a;
    background: white;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 13px;
    min-width: 32px;
    text-align: center;
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .dashboard-stats-card {
    .stats-header {
      flex-direction: column;
      
      .header-item {
        border-right: none;
        border-bottom: 1px solid #e2e8f0;
        
        &:last-child {
          border-bottom: none;
        }
      }
    }
    
    .stats-content {
      flex-direction: column;
      
      .stats-section::after {
        display: none;
      }
      
      .stats-section:not(:last-child) {
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .stats-section {
    min-width: 100%;
  }
}

// Mobil görünüm için yeni stiller
.mobile-header {
  background: #f8fafc !important;
  border-bottom: 1px solid #e2e8f0;
  
  .header-icon {
    margin-right: 12px;
    font-size: 20px;
  }
}

::v-deep .v-expansion-panel {
  &::before {
    box-shadow: none !important;
  }

  .v-expansion-panel-header {
    padding: 12px 16px;
    min-height: auto;
    font-weight: 600;
    font-size: 14px;
    color: #1e40af;
  }

  .v-expansion-panel-content__wrap {
    padding: 12px 16px;
  }
}

.stats-items {
  .stat-item {
    margin-bottom: 8px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .dashboard-stats-card {
    .stats-content {
      padding: 0;
    }
  }

  .stat-item {
    background: white;
    border: 1px solid #e2e8f0;
    
    &:hover {
      background: #f8fafc;
    }
  }
}

.status-card {
  background: white;
  transition: all 0.2s ease;

  .status-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e293b;
    
    &.text-error {
      color: #ef4444;
    }
  }

  .total-count {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0f172a;
    line-height: 1;

    &.text-error {
      color: #ef4444;
    }
  }

  .status-amount {
    font-size: 1rem;
    color: #64748b;
    font-weight: 500;

    &.text-error {
      color: #ef4444;
      opacity: 0.8;
    }
  }
}

@media (max-width: 600px) {
  .status-card {
    .total-count {
      font-size: 2rem;
    }
  }
}

.sub-status-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.sub-status-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #cbd5e1;
    transform: translateY(-2px);
  }
}

.sub-status-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e293b;
}

.sub-status-values {
  margin-top: 8px;
}

.sub-status-count {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  line-height: 1;
}

.sub-status-price {
  display: block;
  font-size: 0.75rem;
  color: #64748b;
  margin-top: 4px;
}

@media (max-width: 960px) {
  .sub-status-grid {
    grid-template-columns: 1fr;
  }
}

.kpi-card {
  background: #f8fafc !important;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  height: 100%;

  &:hover {
    border-color: #cbd5e1;
    transform: translateY(-1px);
  }

  .kpi-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1e293b;
  }

  .kpi-count {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    background: transparent !important;

    &.success {
      color: #10b981;
    }

    &.error {
      color: #dc2626;
    }
  }

  .kpi-amount {
    font-size: 18px;
    font-weight: bolder;
    color: #2c2c2c;
  }
}

@media (max-width: 600px) {
  .kpi-card {
    .kpi-count {
      font-size: 2rem;
    }
    
    .kpi-amount {
      font-size: 0.87rem;
    }
  }
}

// Date range picker modal genişlik düzeltmesi
::v-deep .daterangepicker {
  width: auto !important;
  
  .ranges {
    float: left;
  }
  
  .drp-calendar {
    max-width: none;
    width: auto;
  }
  
  .calendar-table {
    width: auto;
  }
  
  @media (max-width: 760px) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0;
    width: 90vw !important;
    max-width: 400px !important;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 9999;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    animation: fadeIn 0.2s ease-in-out;
    
    .ranges {
      float: none;
      width: 100%;
      margin-bottom: 10px;
    }
    
    .drp-calendar {
      float: none !important;
      max-width: 100% !important;
      width: 100% !important;
      clear: both;
      margin: 0 auto;
      
      &.left, &.right {
        padding: 8px;
      }
    }
    
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: -1;
      animation: fadeIn 0.2s ease-in-out;
    }
    
    .calendar-table {
      width: 100%;
      
      table {
        width: 100%;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Mobil görünümde overlay arka planı
.daterangepicker-overlay {
  @media (max-width: 760px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
}

.reservation-search {
  max-width: 250px;
  display: flex;
  
  ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  
  .search-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
    height: 40px !important;
  }
  
  @media (max-width: 600px) {
    max-width: 200px;
  }
}

// Input ve buton mobil görünüm optimizasyonu
@media (max-width: 600px) {
  .d-flex {
    flex-wrap: wrap;
    gap: 8px;
    
    .v-btn {
      height: 40px !important;
    }
  }
}
</style>
